import React from 'react';
import { Image as CloudinaryImage } from 'cloudinary-react';
import PropTypes from 'prop-types';

const Image = ({ imageUrl, width, height, crop, className }) => (
  <CloudinaryImage
    publicId={imageUrl}
    cloudName='dan-it-education'
    width={width}
    height={height}
    crop={crop}
    className={className}
  />
);

Image.propTypes = {
  imageUrl: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  crop: PropTypes.string,
  className: PropTypes.string
};

Image.defaultProps = {
  imageUrl: 'choice_test/placeholder_wzh1ur',
  width: 200,
  height: 133,
  crop: 'fill',
  className: ''
};

export default Image;
