import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import mova from 'mova';

const t = mova.ns('test');

const FinishTestModal = ({ open, handleClose, finish }) => {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">{t('finishing')}</DialogTitle>
      <DialogContent>
        <Typography variant='h6' style={{color: 'red', marginBottom: '20px'}}>
          {t('confirmFinish1')}
        </Typography>
        <Typography variant='body1' style={{marginBottom: '20px'}}>
          {t('confirmFinish2')}
        </Typography>
        <Typography variant='body1'>
          {t('confirmFinish3')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button type="button" variant="text" color="primary" onClick={handleClose}>{t('cancel')}</Button>
        <Button type="button" variant="contained" color="secondary" onClick={finish}>{t('finish')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FinishTestModal;
