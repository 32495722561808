import Actions from '../actions/actionTypes';

function initialLoading(state = true, action) {
  if (action.type === Actions.INITIAL_DATA_LOADING) {
    return action.newState;
  }
  return state;
}

export default initialLoading;
