import React, { Fragment, useCallback, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import mova from 'mova';
import Preloader from '../../../components/common/Preloader/Preloader';
import ChoiceTestCard from '../../../components/common/ChoiceTestCard/ChoiceTestCard';
import { addEditChoiceTest, getChoiceTests } from '../../../store/actions/choiceTestsActions';
import AddEditChoiceTestModal from '../../../components/admin/AddEditChoiceTestModal/AddEditChoiceTestModal';
import AdminMenu from '../../../components/admin/ChoiceAdminMenu/ChoiceAdminMenu';
import clsx from 'clsx';

const t = mova.ns('admin');

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  actionsRow: {
    marginBottom: theme.spacing(1)
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  }
}));

const ChoiceTests = ({ history, tests, loading, getTests, confirmAddTest }) => {
  const classes = useStyles();
  const [addingTest, setAddingTest] = useState(false);

  useLayoutEffect(() => {
    getTests();
  }, [getTests]);

  const showTestResults = useCallback((test, h) => {
    h.push(`/choice/${test.id}/results`)
  }, []);

  let detailedView;

  if (loading) {
    detailedView = <Preloader />;
  } else if (tests.length === 0) {
    detailedView = <Typography>{t('noChoiceTests')}</Typography>;
  } else {
    detailedView = (
      <div className={classes.cardsContainer}>
        {tests.map((t) => <ChoiceTestCard key={t.id} test={t} adminView onClick={() => showTestResults(t, history)} />)}
      </div>
    );
  }

  const openAddTestModal = useCallback(() => {
    setAddingTest(true);
  }, []);

  const closeAddTestModal = useCallback(() => {
    setAddingTest(false);
  }, []);

  return (
    <Fragment>
      <Paper className={classes.root}>
        <AddEditChoiceTestModal
          open={addingTest}
          handleClose={closeAddTestModal}
          confirmAction={confirmAddTest}
        />
        <AdminMenu />
        <div className={clsx(classes.flexRow, classes.actionsRow)}>
          <Button variant='contained' color='primary' onClick={openAddTestModal} className={classes.button}>
            {t('addChoiceTest')}
          </Button>
        </div>
        {detailedView}
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = ({ choiceTests }) => ({
  tests: choiceTests.content,
  loading: choiceTests.fetching
});

const mapDispatchToProps = (dispatch) => {
  return {
    getTests: () => dispatch(getChoiceTests()),
    confirmAddTest: (test, imageRef) => dispatch(addEditChoiceTest(test, imageRef))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoiceTests);
