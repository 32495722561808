import React, { Component } from 'react'
import ReduxToastr from 'react-redux-toastr'

class ToastrMessage extends Component {
  render () {
    return (
      <div>
        <ReduxToastr
          timeOut={5000}
          newestOnTop={true}
          position='bottom-left'
          preventDuplicates
          transitionIn='fadeIn'
          transitionOut='fadeOut'/>
      </div>
    )
  }
}

export default ToastrMessage
