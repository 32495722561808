import Actions from '../actions/actionTypes';

const initialState = null;

function user(state = initialState, action) {
  switch (action.type) {
    case Actions.USER_LOADED:
      return action.payload;
    default:
      return state;
  }
}

export default user;
