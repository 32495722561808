import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ROLE_ADMIN } from '../util/constants';
import ViewSelector from '../pages/common/ViewSelector/ViewSelector';
import StartTest from '../pages/user/StartTest/StartTest';
import Users from '../pages/admin/Users/Users';
import Tests from '../pages/admin/Tests/Tests';
import TestResults from '../pages/admin/TestResults/TestResults';
import ChoiceTests from '../pages/admin/ChoiceTests/ChoiceTests';
import SignIn from '../pages/common/SignIn/SignIn';
import Forbidden from '../pages/user/Forbidden/Forbidden';
import Page404 from '../pages/common/Page404/Page404';
import ChoiceTestUsers from '../pages/admin/ChoiceTestUsers/ChoiceTestUsers';
import ChoiceTestResults from '../pages/admin/ChoiceTestResults/ChoiceTestResults';
import ChoiceTestQuestions from '../pages/admin/ChoiceTestQuestions/ChoiceTestQuestions';
import MyTests from '../pages/user/MyTests/MyTests';
import ChoiceTest from '../pages/user/ChoiceTest/ChoiceTest';

class AppRoutes extends Component {
  render() {
    const { user } = this.props;

    const authenticated = user && user.email;
    const authorized = user && user.role === ROLE_ADMIN;

    return (
      <Switch>
        <Route exact path='/login' component={SignIn} />
        <Route exact path='/forbidden' component={Forbidden} />

        <ProtectedRoute exact path='/' authenticated={authenticated} component={MyTests} />
        <ProtectedRoute exact path='/my/programming' authenticated={authenticated} component={ViewSelector} />
        <ProtectedRoute exact path='/my/choice/:testId' authenticated={authenticated} component={ChoiceTest} />
        <ProtectedRoute exact path='/start-test' authenticated={authenticated} component={StartTest} />

        <Redirect exact from='/programming' to='/programming/users'/>
        <AuthorizedRoute
          exact
          path='/programming/users'
          authenticated={authenticated}
          authorized={authorized}
          component={Users}
        />
        <AuthorizedRoute
          exact
          path='/programming/tests'
          authenticated={authenticated}
          authorized={authorized}
          component={Tests}
        />
        <AuthorizedRoute
          exact
          path='/programming/tests/:testId'
          authenticated={authenticated}
          authorized={authorized}
          component={TestResults}
        />
        <AuthorizedRoute
          exact
          path='/choice'
          authenticated={authenticated}
          authorized={authorized}
          component={ChoiceTests}
        />
        <AuthorizedRoute
          exact
          path='/choice/:testId/questions'
          authenticated={authenticated}
          authorized={authorized}
          component={ChoiceTestQuestions}
        />
        <AuthorizedRoute
          exact
          path='/choice/:testId/users'
          authenticated={authenticated}
          authorized={authorized}
          component={ChoiceTestUsers}
        />
        <AuthorizedRoute
          exact
          path='/choice/:testId/results'
          authenticated={authenticated}
          authorized={authorized}
          component={ChoiceTestResults}
        />
        <AuthorizedRoute
          exact
          path='/choice/:testId/:solutionId/details'
          authenticated={authenticated}
          authorized={authorized}
          component={TestResults}
        />

        <Route path='*' component={Page404} />
      </Switch>
    );
  }
}

export const ProtectedRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route {...rest} render={(props) => authenticated ? <Component {...props} /> : <Redirect to='/login' />} />
);

export const AuthorizedRoute = ({ component: Component, authenticated, authorized, ...rest }) => (
  <Route {...rest} render={(props) => {
    if (!authenticated) {
      return <Redirect to='/login' />;
    } else if (!authorized) {
      return <Redirect to='/forbidden' />;
    } else {
      return <Component {...props} />;
    }
  }} />
);

const mapStateToProps = ({ user }) => {
  return {
    user
  };
};

export default withRouter(connect(mapStateToProps)(AppRoutes));
