import Actions from '../actions/actionTypes';

const initialState = {
  test: null,
  fetching: false,
  users: {
    content: [],
    fetching: true,
    totalElements: 0,
    totalPages: 0,
    page: 0,
    size: 10,
    selected: []
  },
  questions: {
    content: [],
    fetching: true,
    totalElements: 0,
    totalPages: 0,
    page: 0,
    size: 10,
    selected: []
  },
  results: {
    content: [],
    fetching: true,
    totalElements: 0,
    totalPages: 0,
    page: 0,
    size: 10,
    selected: []
  }
};

function tests(state = initialState, action) {
  switch (action.type) {
    case Actions.SAVE_CHOICE_TEST:
      return { ...state, test: action.payload };
    case Actions.CHOICE_TEST_FETCHING:
      return { ...state, fetching: action.payload };

    case Actions.SAVE_CHOICE_TEST_USERS:
      return { ...state, users: { ...state.users, ...action.payload } };
    case Actions.CHOICE_TEST_USERS_FETCHING:
      return { ...state, users: { ...state.users, fetching: action.payload } };
    case Actions.CHANGE_CHOICE_TEST_USERS_PAGE:
      return { ...state, users: { ...state.users, page: action.payload } };
    case Actions.CHANGE_CHOICE_TEST_USERS_PAGE_SIZE:
      return { ...state, users: { ...state.users, size: action.payload } };
    case Actions.CHOICE_TEST_USER_SELECTED:
      if (state.users.selected.includes(action.payload)) {
        return {
          ...state,
          users: { ...state.users, selected: state.users.selected.filter(item => item !== action.payload) }
        };
      } else {
        return {
          ...state,
          users: { ...state.users, selected: [...state.users.selected, action.payload] }
        };
      }
    case Actions.CHOICE_TEST_USERS_DELETED:
      return { ...state, users: { ...state.users, selected: [] } };

    case Actions.SAVE_CHOICE_TEST_QUESTIONS:
      return { ...state, questions: { ...state.questions, ...action.payload } };
    case Actions.CHOICE_TEST_QUESTIONS_FETCHING:
      return { ...state, questions: { ...state.questions, fetching: action.payload } };
    case Actions.CHANGE_CHOICE_TEST_QUESTIONS_PAGE:
      return { ...state, questions: { ...state.questions, page: action.payload } };
    case Actions.CHANGE_CHOICE_TEST_QUESTIONS_PAGE_SIZE:
      return { ...state, questions: { ...state.questions, size: action.payload } };
    case Actions.CHOICE_TEST_QUESTION_SELECTED:
      if (state.questions.selected.includes(action.payload)) {
        return {
          ...state,
          questions: { ...state.questions, selected: state.questions.selected.filter(item => item !== action.payload) }
        };
      } else {
        return {
          ...state,
          questions: { ...state.questions, selected: [...state.questions.selected, action.payload] }
        };
      }
    case Actions.CHOICE_TEST_QUESTIONS_DELETED:
      return { ...state, questions: { ...state.questions, selected: [] } };
    case Actions.UPDATE_CHOICE_TEST_QUESTION: {
      const indexToUpdate = state.questions.content.findIndex(q => q.id === action.payload.id);
      const newArray = [...state.questions.content];
      newArray[indexToUpdate] = { ...action.payload };
      return { ...state, questions: { ...state.questions, content: newArray } };
    }
    case Actions.UPDATE_CHOICE_TEST_OPTIONS: {
      const { questionId, options } = action.payload;
      const indexToUpdate = state.questions.content.findIndex(q => q.id === questionId);
      const newArray = [...state.questions.content];
      newArray[indexToUpdate] = { ...newArray[indexToUpdate], options };
      return { ...state, questions: { ...state.questions, content: newArray } };
    }

    case Actions.SAVE_CHOICE_TEST_RESULTS:
      return { ...state, results: { ...state.results, ...action.payload } };
    case Actions.CHOICE_TEST_RESULTS_FETCHING:
      return { ...state, results: { ...state.results, fetching: action.payload } };
    case Actions.CHANGE_CHOICE_TEST_RESULTS_PAGE:
      return { ...state, results: { ...state.results, page: action.payload } };
    case Actions.CHANGE_CHOICE_TEST_RESULTS_PAGE_SIZE:
      return { ...state, results: { ...state.results, size: action.payload } };
    case Actions.CHOICE_TEST_RESULT_SELECTED:
      if (state.results.selected.includes(action.payload)) {
        return {
          ...state,
          results: { ...state.results, selected: state.results.selected.filter(item => item !== action.payload) }
        };
      } else {
        return {
          ...state,
          results: { ...state.results, selected: [...state.results.selected, action.payload] }
        };
      }
    case Actions.CHOICE_TEST_RESULTS_DELETED:
      return { ...state, results: { ...state.results, selected: [] } };
    default:
      return state;
  }
}

export default tests;
