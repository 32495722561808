import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import mova from 'mova';
import { makeStyles } from '@material-ui/core/styles';

const t = mova.ns('admin');

const useStyles = makeStyles(theme => ({
  row: {
    margin: theme.spacing(1, 0)
  }
}));

const AddUserModal = (props) => {
  const { open, handleClose, addUsers } = props;
  const classes = useStyles();

  const [users, setUsers] = useState('');
  const [password, setPassword] = useState('');

  const confirmAddUsers = () => {
    addUsers(users, password);
    handleClose();
  };

  const handleChangeUsers = (e) => {
    setUsers(e.target.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  return (
    <Dialog fullWidth onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{t('addUsers')}</DialogTitle>
      <DialogContent>
        <div className={classes.row}>
          <TextareaAutosize
            value={users}
            rows={10}
            cols={65}
            onChange={handleChangeUsers}
            placeholder={t('emailsPlaceholder')}
          />
        </div>
        <div className={classes.row}>
          <TextField
            variant='outlined'
            value={password}
            placeholder={t('passwordPlaceholder')}
            onChange={handleChangePassword}
            fullWidth
          />
        </div>
        <div className={classes.row}>
          <Button variant='contained' color='secondary' onClick={confirmAddUsers}>{t('add')}</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddUserModal;
