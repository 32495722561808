import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MainListItems from './MainListItems';
import Typography from '@material-ui/core/Typography';
import mova from 'mova';
import { changeActiveItem, resetCode, submitTask, updateActiveTaskCode } from '../../../store/actions/taskActions';
import { finishTest } from '../../../store/actions/testActions';
import Code from '../../../components/user/Code/Code';
import Timer from '../../../components/user/Timer/Timer';
import TestInstructionsDialog from '../../../components/user/TestInstructionsDialog/TestInstructionsDialog';
import FinishTestModal from '../FinishTestModal/FinishTestModal';

const t = mova.ns('test');

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    height: 'calc(100vh - 64px)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  submit: {
    margin: theme.spacing(1, 0, 1, 1),
  },
  descriptionPaper: {
    boxSizing: 'border-box',
    width: 'calc(100% - 20px)',
    height: 'calc(45vh - 64px)',
    margin: '10px'
  },
  codeWrapper: {
    display: 'flex'
  },
  rightPartWrapper: {
    width: '50%'
  },
  messageFromServer: {
    height: '150px',
    margin: '10px',
    padding: '10px'
  },
  resultWrapper: {
    display: 'flex'
  },
  expected: {
    width: '50%',
    height: 'calc(45vh - 60px - 170px)',
    margin: '10px',
    padding: '10px'
  },
  output: {
    width: '50%',
    height: 'calc(45vh - 60px - 170px)',
    margin: '10px',
    padding: '10px'
  },
  code: {
    width: 'calc(49% - 20px)',
    height: 'calc(45vh - 60px)'
  },
  content: {
    flex: '1 0 auto',
    maxWidth: 'calc(100% - 240px)'
  },
  wideContent: {
    maxWidth: 'calc(100% - 72px)'
  },
  timer: {
    position: 'absolute',
    top: '13px',
    left: '60px'
  },
  paperHeader: {
    color: 'rgba(0, 0, 0, 0.57)',
    fontSize: '16px'
  },
  scrollingPaper: {
    overflow: 'auto'
  },
  whiteSpace: {
    whiteSpace: 'pre'
  },
  actionButtons: {
    display: 'flex',
    padding: '10px'
  },
  spacer: {
    flex: `1 1 10%`
  },
  red: {
    color: 'red'
  },
  green: {
    color: 'green'
  }
}));

function Test(props) {
  const {
    user, tasks, currentTask, updateCode, resetCode, codeVersion, submitTask, finishTest, changeActiveItem,
    completedTasks, lang
  } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [finishTestOpen, setFinishTestOpen] = useState(false);

  const toggleDrawerOpen = () => {
    setOpen(!open);
  };

  const viewInstructions = () => setModalOpen(true);
  const closeInstructions = () => setModalOpen(false);
  const openFinishTestDialog = () => setFinishTestOpen(true);
  const closeFinishTestDialog = () => setFinishTestOpen(false);

  const userCompletion = user.test.solutions.slice().reverse().find(sol => sol.taskNumber === currentTask.ordinalNumber);
  const userCode = userCompletion ? userCompletion.code : null;

  const currentCode = currentTask.code || userCode || currentTask.initialCode;

  return (
    <div className={classes.root}>
      <TestInstructionsDialog open={modalOpen} handleClose={closeInstructions} />
      <FinishTestModal open={finishTestOpen} handleClose={closeFinishTestDialog} finish={finishTest} />
      <Drawer
        variant='permanent'
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        {
          open &&
          <Timer
            millis={user.test.timeLeft}
            className={classes.timer}
            onFinish={finishTest}
          />
        }
        <div className={classes.toolbarIcon}>
          <IconButton onClick={toggleDrawerOpen}>
            {open && <ChevronLeftIcon />}
            {!open && <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <MainListItems
            completedTasks={completedTasks}
            tasks={tasks.data}
            changeActiveItem={changeActiveItem}
            currentTask={currentTask}
          />
        </List>
      </Drawer>
      <main className={clsx(classes.content, !open && classes.wideContent)}>
        <Paper className={classes.descriptionPaper}>
          <iframe width='100%' height='100%' src={`/tasks/${lang}/${currentTask.name}.html`} title='Test task description' />
        </Paper>
        <div className={classes.codeWrapper}>
          <Code code={currentCode} onChange={updateCode} versionCounter={codeVersion} className={classes.code} />
          <div className={classes.rightPartWrapper}>
            <Paper className={clsx(classes.messageFromServer, classes.scrollingPaper)}>
              <Typography className={classes.paperHeader} variant='h6'>{t('responseFromServer')}</Typography>
              <div className={classes.whiteSpace}>{currentTask.serverResponse}</div>
            </Paper>
            <div className={classes.resultWrapper}>
              <Paper className={clsx(classes.output, classes.scrollingPaper)}>
                <Typography className={classes.paperHeader} variant='h6'>{t('yourResult')}</Typography>
                <div className={clsx(classes.whiteSpace, classes.red)}>{currentTask.output}</div>
              </Paper>
              <Paper className={clsx(classes.expected, classes.scrollingPaper)}>
                <Typography className={classes.paperHeader} variant='h6'>{t('expectedResult')}</Typography>
                <div className={clsx(classes.whiteSpace, classes.green)}>{currentTask.expected}</div>
              </Paper>
            </div>
          </div>
        </div>
        <div className={classes.actionButtons}>
          <Button
            type='button'
            variant='contained'
            color='primary'
            className={classes.submit}
            onClick={() => submitTask(currentTask)}
          >
            {t('submit')}
          </Button>
          <Button
            type='button'
            variant='outlined'
            color='secondary'
            className={classes.submit}
            onClick={resetCode}
          >
            {t('reset')}
          </Button>
          <Button
            type='button'
            variant='text'
            color='secondary'
            className={classes.submit}
            onClick={viewInstructions}
          >
            {t('instructions')}
          </Button>
          <div className={classes.spacer} />
          <Button
            type='button'
            variant='contained'
            color='secondary'
            className={classes.submit}
            onClick={openFinishTestDialog}
          >
            {t('finishButton')}
          </Button>
        </div>
      </main>
    </div>
  );
}

const mapStateToProps = ({ user, tasks, config }) => {
  return {
    user,
    currentTask: tasks.data[tasks.active],
    codeVersion: tasks.codeVersion,
    tasks,
    completedTasks: tasks.completedTasks,
    lang: config.lang
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateCode: (code) => dispatch(updateActiveTaskCode(code)),
    resetCode: () => dispatch(resetCode()),
    submitTask: (task) => dispatch(submitTask(task)),
    finishTest: () => dispatch(finishTest()),
    changeActiveItem: (item) => dispatch(changeActiveItem(item))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Test);
