import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import tests from './tests';
import initialLoading from './initialLoading';
import user from './user';
import tasks from './tasks';
import users from './users';
import config from './config';
import choiceTests from './choiceTests';
import choiceTest from './choiceTest';

export default combineReducers({
  tests,
  tasks,
  user,
  users,
  config,
  initialLoading,
  choiceTests,
  choiceTest,
  toastr: toastrReducer
});
