import React, { memo, useEffect, useState } from 'react';
import { useField } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import clsx from 'clsx';
import AnswerOptionImage from '../AnswerOptionImage/AnswerOptionImage';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0)
  },
  imagesRoot: {
    flexDirection: 'row'
  },
  optionRoot: {
    maxWidth: '297px',
    marginBottom: theme.spacing(2),
    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    }
  },
  hidden: {
    display: 'none'
  },
  margin0: {
    marginLeft: 0,
  },
  label: {
    paddingTop: theme.spacing(2)
  },
  error: {
    marginBottom: theme.spacing(2)
  }
}));

const FormikCheckboxGroup = ({ name, options, questionId }) => {
  const [field, meta, helpers] = useField(name);
  const [value, setValue] = useState([]);
  const classes = useStyles();
  const hasImages = options.some(o => !!o.imageUrl);

  useEffect(() => {
    setValue([]);
    helpers.setTouched(false);
  }, [questionId]); // eslint-disable-line

  useEffect(() => {
    if (value.length === 0) {
      helpers.setValue('');
    } else {
      helpers.setValue(JSON.stringify(value));
    }
  }, [value]); // eslint-disable-line

  const handleChange = (e) => {
    const { name, checked } = e.target;

    if (checked) {
      setValue([...value, +name]);
    } else {
      setValue(value.filter(i => i !== +name));
    }
  };

  const imageClicked = (id) => {
    if (value.includes(id)) {
      setValue(value.filter(i => i !== id));
    } else {
      setValue([...value, id]);
    }
  };

  const optionItems = options.map(o => (
    <FormControl key={o.id} className={clsx({ [classes.optionRoot]: hasImages })}>
      {!!o.imageUrl && <AnswerOptionImage option={o} onClick={imageClicked} value={value} />}
      <FormControlLabel
        control={
          <Checkbox
            checked={value.includes(o.id)}
            onChange={handleChange}
            name={String(o.id)}
            className={clsx({ [classes.hidden]: hasImages })}
          />
        }
        label={o.text}
        onBlur={field.onBlur}
        className={clsx({ [classes.margin0]: hasImages })}
      />
    </FormControl>
  ));

  return (
    <FormControl component='fieldset'>
      <FormGroup className={clsx(classes.root, { [classes.imagesRoot]: hasImages })}>
        {optionItems}
      </FormGroup>
      {
        meta.touched && !!meta.error &&
        <Typography variant='body1' color='secondary' className={classes.error}>{meta.error}</Typography>
      }
    </FormControl>
  );
};

export default memo(FormikCheckboxGroup);

