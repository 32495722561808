import React from 'react';
import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import mova from 'mova';
import { connect } from 'react-redux';

const t = mova.ns('test');

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));

function TestInstructionsDialog(props) {
  const { open, handleClose, lang } = props;
  const classes = useStyles();

  return (
    <Dialog
      fullScreen
      className={classes.root}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title"></DialogTitle>
      <DialogContent>
        <iframe width='100%' height='95%' title={t('instructions')} src={`/intro_${lang}.html`} />
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = ({ config }) => ({
  lang: config.lang
});

export default connect(mapStateToProps)(TestInstructionsDialog);
