import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import mova from 'mova';

const t = mova.ns('admin');

const styles = theme => ({
  table: {
    minWidth: 1020,
  },
  bold: {
    fontWeight: 'bold'
  },
  completed: {
    color: 'green',
    fontWeight: 'bold'
  },
  partlyCompleted: {
    color: '#ffab00',
    fontWeight: 'bold'
  },
  incorrect: {
    color: 'red',
    fontWeight: 'bold'
  }
});

class GroupedResultsTable extends Component {
  render() {
    const { test, tasks, classes } = this.props;

    const headerCells = tasks.map((task, index) => (
      <TableCell
        key={index}
        align='left'
        padding='none'
      >
        {task.name}
      </TableCell>
    ));

    const tries = tasks.map((task, index) => (
      <TableCell
        key={index}
        className={taskStatusClass(test, task, classes)}
        padding='none'
      >
        {taskTries(test, task)}
      </TableCell>
    ));

    const tests = tasks.map((task, index) => (
      <TableCell
        key={index}
        className={taskStatusClass(test, task, classes)}
        padding='none'
      >
        {completedTests(test, task)} / {task.tests}
      </TableCell>
    ));

    const score = tasks.map((task, index) => (
      <TableCell
        key={index}
        className={taskStatusClass(test, task, classes)}
        padding='none'
      >
        {maxResult(test, task)} / {task.score}
      </TableCell>
    ));

    return (
      <Table className={classes.table} aria-labelledby='tableTitle'>
        <TableHead>
          <TableRow>
            <TableCell />
            {headerCells}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.bold}>{t('tries')}</TableCell>
            {tries}
          </TableRow>

          <TableRow>
            <TableCell className={classes.bold}>{t('tests')}</TableCell>
            {tests}
          </TableRow>

          <TableRow>
            <TableCell className={classes.bold}>{t('score')}</TableCell>
            {score}
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

const taskStatusClass = (test, task, classes) => {
  if (completedTests(test, task) === task.tests) {
    return classes.completed;
  } else if (completedTests(test, task) > 0) {
    return classes.partlyCompleted;
  } else if (taskTries(test, task) > 0) {
    return classes.incorrect;
  }
};

const taskSolutions = (test, task) => test.solutions.filter(s => s.task.includes(task.name));

const taskTries = (test, task) => taskSolutions(test, task).length;

const completedTests = (test, task) => Math.max(...taskSolutions(test, task).map(s => s.testsCompleted), 0);

const maxResult = (test, task) => Math.max(...taskSolutions(test, task).map(s => s.score), 0);

const mapStateToProps = ({ tasks }) => {
  return {
    tasks: tasks.data
  };
};

export default withStyles(styles)(connect(mapStateToProps)(GroupedResultsTable));
