import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import mova from 'mova';
import { Link } from 'react-router-dom';

const t = mova.ns('page404');

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center'
  },
  h1: {
    marginBottom: '1.6rem',
    fontWeight: 500,
    fontSize: '7.5rem',
    lineHeight: 1.167
  },
  h5: {
    marginBottom: '1.6rem',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '2rem',
    fontWeight: 400,
    lineHeight: 1.334,
  },
  link: {
    fontSize: '1.5rem',
  }
}));

const Page404 = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1 className={classes.h1}>404</h1>
      <h5 className={classes.h5}>{t('text')}</h5>
      <div><Link to='/' className={classes.link}>{t('toMain')}</Link></div>
    </div>
  );
};

export default Page404;
