import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import mova from 'mova';
import TestInstructionsDialog from '../../../components/user/TestInstructionsDialog/TestInstructionsDialog';
import { startTest } from '../../../store/actions/testActions';

const t = mova.ns('test');

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function StartTest(props) {
  const { startTest } = props;
  const classes = useStyles();

  const [instructionsOpen, setInstructionsOpen] = useState(false);

  const viewInstructions = () => setInstructionsOpen(true);
  const closeInstructions = () => setInstructionsOpen(false);

  return (
    <Container component="main" maxWidth="xs">
      <TestInstructionsDialog open={!!instructionsOpen} handleClose={closeInstructions} />
      <div className={classes.paper}>
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={viewInstructions}
        >
          {t('readInstructions')}
        </Button>
      </div>

      <div>
        <Typography variant='h6'>
          {t('description')}
        </Typography>
      </div>

      <div>
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={startTest}
        >
          {t('start')}
        </Button>
      </div>
    </Container>
  );
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    startTest: () => dispatch(startTest())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StartTest);
