export default {
  name: 'ru',
  base: {
    signin: 'Логин',
    logout: 'Выйти',
    email: 'Е-мейл',
    password: 'Пароль',
    cookies: 'Необходимо включить куки чтобы использовать этот сервис',
    success: 'Задание выполнено успешно!'
  },
  tasks: {
    1: 'Моя первая программа',
    2: 'Простой вывод текста',
    3: 'Арифметика',
    4: 'Программа с условием',
    5: 'Учимся считать',
    6: 'Счастливые числа',
    7: 'Теперь идем наоборот',
    8: 'Больше математики',
    9: 'Учат в школе',
    10: 'Калькулятор'
  },
  test: {
    instructions: 'Руководство к тесту',
    start: 'Начать тест',
    readInstructions: 'Прочитать руководство',
    description: 'У Вас будет полтора часа на выполнение теста. После того как Вы начнете, у Вас не будет возможности приостановить выполнение теста и вернуться на эту страницу. Пожалуйста, вначале внимательно прочитайте руководство к тесту.',
    confirmFinish1: 'Вы уверены что хотите завершить тест? Вы не сможете продолжить выполнение других заданий.',
    confirmFinish2: 'Вы будете перенаправлены на другую страницу и не сможете вернуться к данному окну.',
    confirmFinish3: 'Нажимая кнопку Завершить тест, Вы подтверждаете, что выполнили максимум заданий и согласны со своим результатом.',
    finishing: 'Завершение теста',
    cancel: 'Отмена',
    finish: 'Завершить',
    submit: 'Отправить',
    reset: 'Начальный код',
    finishButton: 'Завершить тест',
    responseFromServer: 'Ответ сервера',
    yourResult: 'Результат Вашего кода',
    expectedResult: 'Ожидаемый результат'
  },
  admin: {
    tries: 'Попытки',
    tests: 'Тесты',
    score: 'Результат',
    task: 'Задание',
    submissionTime: 'Время сдачи',
    completed: 'Тестов выполнено',
    total: 'Всего тестов',
    selected: 'выбрано',
    applicantEmail: 'Е-мейл кандидата',
    applicant: 'Кандидат',
    question: 'Вопрос',
    image: 'Изображение',
    noImage: 'Нет изображения',
    answers: 'Ответы',
    answer: 'Ответ',
    answerType: 'Тип ответа',
    correct: 'Правильный',
    incorrect: 'Неправильный',
    startDate: 'Дата начала',
    finishDate: 'Дата окончания',
    startedTest: 'Тест начат',
    finishedTest: 'Тест закончен',
    testDuration: 'Длительность теста',
    testResult: 'Результат теста',
    testsCompleted: 'Тестов выполнено',
    testsTotal: 'Всего тестов',
    result: 'Общий результат',
    noTests: 'Тестов не найдено',
    searchPlaceholder: 'Поиск...',
    search: 'Поиск',
    clear: 'Очистить',
    delete: 'Удалить',
    exportToExcel: 'Выгрузить в Excel',
    actions: 'Действия',
    addUsers: 'Добавить пользователей',
    addQuestion: 'Добавить вопрос',
    addOption: 'Добавить вариант ответа',
    add: 'Добавить',
    edit: 'Редактировать',
    noUsers: 'Пользователей не найдено',
    noQuestions: 'Вопросов не найдено',
    emailsPlaceholder: 'Имейлы пользователей',
    copy: 'Копировать',
    viewTest: 'Открыть тест',
    password: 'Пароль',
    passwordCopied: 'Пароль скопирован в буфер обмена',
    passwordPlaceholder: 'Пароль (опционально)',
    menu: {
      userManagement: 'Пользователи',
      availableTests: 'Тесты',
      userTest: 'Тест кандидата',
      choiceTests: 'Тесты',
      choiceTestQuestions: 'Вопросы',
      choiceTestUsers: 'Пользователи',
      choiceTestDetails: 'Детали теста',
      choiceTestCompleted: 'Пройденные тесты'
    },
    editUserEmail: 'Изменение имени пользователя',
    changeName: 'Изменить имя',
    addChoiceTest: 'Добавить тест',
    cancel: 'Отмена',
    newUserName: 'Новое имя',
    noChoiceTests: 'Тестов не найдено',
    forms: {
      addChoiceTest: 'Добавить тест',
      editChoiceTest: 'Редактировать тест',
      addQuestion: 'Добавить вопрос',
      editQuestion: 'Редактировать вопрос',
      addOption: 'Добавить вариант ответа',
      editOption: 'Редактировать вариант ответа',
      namePlaceholder: 'Название',
      questionPlaceholder: 'Вопрос',
      optionPlaceholder: 'Текст ответа',
      noOfQuestionsPlaceholder: 'Количество вопросов',
      minutesPlaceholder: 'Минут на выполнение',
      dateStartPlaceholder: 'Дата и время начала',
      dateFinishPlaceholder: 'Дата и время конца',
      imagePlaceholder: 'Изображение',
      isCorrectPlaceholder: 'Ответ правильный',
      yes: 'Да',
      no: 'Нет',
      add: 'Добавить',
      edit: 'Редактировать',
      validation: {
        required: 'Нельзя отправить пустой ответ',
        min: 'Нельзя указать меньше <=value>'
      }
    },
  },
  thankYou: {
    text: 'Спасибо за прохождение тестирования. Обратитесь к Вашему менеджеру, чтобы узнать свой результат.'
  },
  page404: {
    text: 'Извините, запрашиваемая страница была не найдена',
    toMain: 'Вернуться на главную страницу'
  },
  header: {
    programming: 'Программирование',
    other: 'Другие тесты'
  },
  testCard: {
    minutes: 'минут',
    questions: 'вопросов',
    tasks: 'задач',
    totalQuestions: 'вопросов всего',
    totalUsers: 'пользователей добавлено',
    start: 'Начать тест',
    continue: 'Продолжить тест',
    editTest: 'Редактировать',
    deleteTest: 'Удалить',
    questionsLink: 'Вопросы',
    users: 'Пользователи',
    deleteModalTitle: 'Вы уверены, что хотите удалить данный тест?',
    deleteModalText: 'Все его вопросы, пользователи и результаты будут удалены из системы',
    frontEnd: 'Тест на курс FrontEnd',
    fullStack: 'Тест на курс Full Stack',
    dateStart: 'Дата начала',
    dateFinish: 'Дата конца'
  },
  confirmDeleteModal: {
    confirm: 'Подтвердить',
    cancel: 'Отмена'
  },
  myTests: {
    list: 'Список доступных тестов'
  },
  choiceTest: {
    question: 'Вопрос',
    selectAnswer: 'Выберите правильный ответ',
    of: 'из',
    finishButton: 'Завершить тест',
    submitAnswer: 'Отправить',
    severalAnswers: 'В данном вопросе можно выбрать несколько вариантов ответа'
  }
};
