import React, { Fragment, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { addEditOption, deleteOption } from '../../../../store/actions/choiceTestActions';
import AddEditOptionModal from '../../../../components/admin/AddEditOptionModal/AddEditOptionModal';
import Image from '../../../../components/common/Image/Image';
import mova from 'mova';
import clsx from 'clsx';
import { WH_PROPORTION } from '../../../../util/constants';

const t = mova.ns('admin');

const useStyles = makeStyles(theme => ({
  icon: {
    position: 'relative',
    top: '7px',
    marginLeft: theme.spacing(1)
  },
  green: {
    color: 'green'
  }
}));

const QuestionOptions = ({ question, testId, open, addEditOption, deleteOption }) => {
  const [addingOption, setAddingOption] = useState(false);
  const [optionToUpdate, setOptionToUpdate] = useState(null);

  const classes = useStyles();

  const openAddOptionModal = useCallback(() => {
    setAddingOption(true);
  }, []);

  const closeAddOptionModal = useCallback(() => {
    setAddingOption(false);
  }, []);

  const openEditOptionModal = useCallback((option) => {
    setOptionToUpdate(option);
  }, []);

  const closeEditOptionModal = useCallback(() => {
    setOptionToUpdate(null);
  }, []);

  const confirmAddEditOption = useCallback((option, imageRef) => {
    addEditOption(question.id, option, imageRef);
  }, [question, addEditOption]);

  const handleDeleteOption = useCallback((option) => {
    deleteOption(question.id, option);
  }, [question, deleteOption]);

  const questionOptions = question.options.map((o) => (
    <TableRow key={o.id} hover>
      <TableCell component='th' scope='row'>{o.text}</TableCell>
      <TableCell style={{ verticalAlign: 'middle' }}>
        {o.correct ? t('correct') : t('incorrect')}
        <Typography component='span'>
          {
            o.correct ?
              <CheckIcon className={clsx(classes.icon, classes.green)} /> :
              <ClearIcon className={classes.icon} color='secondary' />
          }
        </Typography>
      </TableCell>
      <TableCell>
        {!!o.imageUrl ? <Image width={102} height={102 / WH_PROPORTION} imageUrl={o.imageUrl} /> : t('noImage')}
      </TableCell>
      <TableCell>
        <IconButton aria-label='edit' onClick={() => openEditOptionModal(o)}><EditIcon /></IconButton>
      </TableCell>
      <TableCell>
        <IconButton aria-label='delete' color='secondary' onClick={() => handleDeleteOption(o)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  ));

  return (
    <Fragment>
      <AddEditOptionModal
        open={addingOption}
        handleClose={closeAddOptionModal}
        confirmAction={confirmAddEditOption}
      />
      <AddEditOptionModal
        open={!!optionToUpdate}
        option={optionToUpdate}
        handleClose={closeEditOptionModal}
        confirmAction={confirmAddEditOption}
      />

      <TableRow key={question.id} className={classes.row}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={1}>
              <Typography variant='h6' gutterBottom component='div'>
                {t('answers')}
                <IconButton aria-label='add' onClick={openAddOptionModal}><AddIcon /></IconButton>
              </Typography>

              <Table size='small' aria-label='answers'>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('answer')}</TableCell>
                    <TableCell>{t('answerType')}</TableCell>
                    <TableCell>{t('image')}</TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>{questionOptions}</TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  addEditOption: (questionId, option, imageRef) => dispatch(addEditOption(questionId, option, imageRef)),
  deleteOption: (questionId, option) => dispatch(deleteOption(questionId, option)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionOptions);
