import Actions from '../actions/actionTypes';

const initialState = {
  data: [],
  loading: true,
  active: 0,
  codeVersion: 1,
  completedTasks: []
};

function tasks(state = initialState, action) {
  switch (action.type) {
    case Actions.TASKS_INFO_LOADNIG:
      return { ...state, loading: action.payload };
    case Actions.TASKS_INFO:
      return { ...state, data: action.payload };
    case Actions.SET_ACTIVE_TASK:
      return { ...state, active: action.payload };
    case Actions.UPDATE_ACTIVE_TASK_CODE:
      state.data[state.active].code = action.payload;
      return { ...state };
    case Actions.RESET_CODE:
      state.data[state.active].code = state.data[state.active].initialCode;
      return { ...state };
    case Actions.INCREMENT_CODE_VERSION:
      return { ...state, codeVersion: state.codeVersion + 1 };
    case Actions.SET_COMPILATION_RESULT:
      state.data[state.active].compilationResult = action.payload;
      return { ...state };
    case Actions.SET_SERVER_RESPONSE:
      state.data[state.active].serverResponse = action.payload.message;
      state.data[state.active].expected = action.payload.expected;
      state.data[state.active].output = action.payload.output;
      return { ...state };
    case Actions.SET_COMPLETED_TASKS:
      return { ...state, completedTasks: action.payload };
    case Actions.TASK_COMPLETED:
      return { ...state, completedTasks: [...state.completedTasks, action.payload] };
    default:
      return state;
  }
}

export default tasks;
