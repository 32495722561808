import Actions from './actionTypes';
import { getCurrentUser } from './userActions';
import { getConfig } from './configActions';

const initData = () => dispatch => {
  dispatch({ type: Actions.INITIAL_DATA_LOADING, newState: true });

  Promise.all([
    dispatch(getCurrentUser()),
    dispatch(getConfig())
  ])
    .then(result => {
      dispatch({ type: Actions.INITIAL_DATA_LOADING, newState: false });
    });
};

export default initData;
