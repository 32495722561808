import api from '../../services/api';
import Actions from './actionTypes';
import { toastr } from 'react-redux-toastr';
import { getCurrentUser } from './userActions';

// USERS

export const getChoiceTestUsers = (testId, page, rowsPerPage, email) => dispatch => {
  const requestParams = {};

  page !== undefined && (requestParams.page = page);
  rowsPerPage !== undefined && (requestParams.size = rowsPerPage);
  email !== undefined && email !== '' && (requestParams.email = email);

  dispatch({ type: Actions.CHOICE_TEST_USERS_FETCHING, payload: true });
  return api.get(`/api/choice-tests/${testId}/users`, requestParams)
    .then(users => {
      dispatch({ type: Actions.SAVE_CHOICE_TEST_USERS, payload: users });
      dispatch({ type: Actions.CHOICE_TEST_USERS_FETCHING, payload: false });
    });
};

export const addChoiceTestUsers = (testId, users, password, page, rowsPerPage) => dispatch => {
  return api.post(`/api/choice-tests/${testId}/users`, { emails: users, password })
    .then(res => {
      if (res) {
        toastr.error('Users that were not added (already attached to this test): ' + res);
      }
      dispatch(getChoiceTestUsers(testId, page, rowsPerPage));
    });
};

export const deleteChoiceTestUsers = (testId, selected, page, rowsPerPage) => dispatch => {
  Promise.all(selected.map(id => api.deleteApi(`/api/choice-tests/${testId}/users/${id}`)))
    .then(() => {
      dispatch({ type: Actions.CHOICE_TEST_USERS_DELETED });
      dispatch(getChoiceTestUsers(testId, page, rowsPerPage));
    });
};

export const selectChoiceTestUser = (e, id) => dispatch => {
  e.stopPropagation();
  dispatch({ type: Actions.CHOICE_TEST_USER_SELECTED, payload: id });
};

export const updateChoiceTestUsersPagination = (page, rowsPerPage) => dispatch => {
  dispatch({ type: Actions.CHANGE_CHOICE_TEST_USERS_PAGE, payload: page });
  dispatch({ type: Actions.CHANGE_CHOICE_TEST_USERS_PAGE_SIZE, payload: rowsPerPage });
};


// QUESTIONS

export const getChoiceTestQuestions = (testId, page, rowsPerPage) => dispatch => {
  const requestParams = {};

  page !== undefined && (requestParams.page = page);
  rowsPerPage !== undefined && (requestParams.size = rowsPerPage);

  dispatch({ type: Actions.CHOICE_TEST_QUESTIONS_FETCHING, payload: true });
  return api.get(`/api/choice-tests/${testId}/questions`, requestParams)
    .then(questions => {
      dispatch({ type: Actions.SAVE_CHOICE_TEST_QUESTIONS, payload: questions });
      dispatch({ type: Actions.CHOICE_TEST_QUESTIONS_FETCHING, payload: false });
    });
};

export const addEditQuestion = (testId, question, imageRef, page, rowsPerPage) => dispatch => {
  const body = new FormData();

  Object.keys(question).forEach(key => {
    if (key !== 'imageUrl') {
      body.append(key, question[key]);
    }
  });

  if (!!question.imageUrl) {
    body.append('imageUrl', imageRef.current.files[0]);
  }

  if (question.id) {
    return api.put(`/api/choice-tests/${testId}/questions/${question.id}`, body)
      .then(updated => {
        dispatch({ type: Actions.UPDATE_CHOICE_TEST_QUESTION, payload: updated });
      });
  } else {
    return api.post(`/api/choice-tests/${testId}/questions`, body)
      .then(() => {
        dispatch(getChoiceTestQuestions(testId, page, rowsPerPage));
      });
  }
};

export const addEditOption = (questionId, option, imageRef) => dispatch => {
  const body = new FormData();

  Object.keys(option).forEach(key => {
    if (key !== 'imageUrl') {
      body.append(key, option[key]);
    }
  });

  if (!!option.imageUrl) {
    body.append('imageUrl', imageRef.current.files[0]);
  }

  const request = option.id ?
    api.put(`/api/options/${questionId}/${option.id}`, body) :
    api.post(`/api/options/${questionId}`, body);

  request.then(options => {
    dispatch({ type: Actions.UPDATE_CHOICE_TEST_OPTIONS, payload: { questionId, options } });
  });
};

export const selectChoiceTestQuestion = (e, id) => dispatch => {
  e.stopPropagation();
  dispatch({ type: Actions.CHOICE_TEST_QUESTION_SELECTED, payload: id });
};

export const deleteChoiceTestQuestions = (testId, selected, page, rowsPerPage) => (dispatch) => {
  Promise.all(selected.map(id => api.deleteApi(`/api/choice-tests/questions/${id}`)))
    .then(() => {
      dispatch({ type: Actions.CHOICE_TEST_QUESTIONS_DELETED });
      dispatch(getChoiceTestQuestions(testId, page, rowsPerPage));
    });
};

export const deleteOption = (questionId, option) => (dispatch) => {
  api.deleteApi(`/api/options/${questionId}/${option.id}`)
    .then(options => {
      dispatch({ type: Actions.UPDATE_CHOICE_TEST_OPTIONS, payload: { questionId, options } });
    });
};

export const updateChoiceTestQuestionsPagination = (page, rowsPerPage) => dispatch => {
  dispatch({ type: Actions.CHANGE_CHOICE_TEST_QUESTIONS_PAGE, payload: page });
  dispatch({ type: Actions.CHANGE_CHOICE_TEST_QUESTIONS_PAGE_SIZE, payload: rowsPerPage });
};


// RESULTS

export const getChoiceTestResults = (testId, page, rowsPerPage, email) => dispatch => {
  const requestParams = {};

  page !== undefined && (requestParams.page = page);
  rowsPerPage !== undefined && (requestParams.size = rowsPerPage);
  email !== undefined && email !== '' && (requestParams.email = email);

  dispatch({ type: Actions.CHOICE_TEST_RESULTS_FETCHING, payload: true });
  return api.get(`/api/choice-tests/${testId}/results`, requestParams)
    .then(users => {
      dispatch({ type: Actions.SAVE_CHOICE_TEST_RESULTS, payload: users });
      dispatch({ type: Actions.CHOICE_TEST_RESULTS_FETCHING, payload: false });
    });
};

export const selectChoiceTestResult = (e, id) => dispatch => {
  e.stopPropagation();
  dispatch({ type: Actions.CHOICE_TEST_RESULT_SELECTED, payload: id });
};

export const deleteChoiceTestResults = (testId, selected, page, rowsPerPage) => (dispatch) => {
  Promise.all(selected.map(id => api.deleteApi(`/api/choice-tests/results/${id}`)))
    .then(() => {
      dispatch({ type: Actions.CHOICE_TEST_RESULTS_DELETED });
      dispatch(getChoiceTestResults(testId, page, rowsPerPage));
    });
};

export const updateChoiceTestResultsPagination = (page, rowsPerPage) => dispatch => {
  dispatch({ type: Actions.CHANGE_CHOICE_TEST_RESULTS_PAGE, payload: page });
  dispatch({ type: Actions.CHANGE_CHOICE_TEST_RESULTS_PAGE_SIZE, payload: rowsPerPage });
};


// STUDENT TEST COMPLETION

export const getMyTestResult = (testId) => dispatch => {
  dispatch({ type: Actions.CHOICE_TEST_FETCHING, payload: true });
  return api.get(`/api/choice-tests/${testId}/results/my`)
    .then(test => {
      dispatch({ type: Actions.SAVE_CHOICE_TEST, payload: test });
      dispatch({ type: Actions.CHOICE_TEST_FETCHING, payload: false });
    })
    .catch(() => {
      setTimeout(() => {
        window.location.replace('/')
      }, 4000);
    });
};

export const answerQuestion = (resultId, answers) => dispatch => {
  return api.put(`/api/choice-tests/${resultId}/answer`, JSON.parse(answers))
    .then(test => {
      dispatch({ type: Actions.SAVE_CHOICE_TEST, payload: test });
    })
    .catch(() => {
      setTimeout(() => {
        window.location.reload()
      }, 3000);
    });
};

export const finishChoiceTest = (resultId) => dispatch => {
  return api.post(`/api/choice-tests/${resultId}/finish`)
    .then(test => {
      dispatch({ type: Actions.SAVE_CHOICE_TEST, payload: test });
      dispatch(getCurrentUser())
    });
};
