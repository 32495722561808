import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
  },
});

class Preloader extends Component {
  render() {
    const { classes } = this.props;

    return (
      <CircularProgress className={classes.progress} />
    );
  }
}

export default withStyles(styles)(Preloader);
