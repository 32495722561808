import React, { useCallback } from 'react';
import { Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import PropTypes from 'prop-types';
import TimeIcon from '@material-ui/icons/AccessTime';
import QuestionIcon from '@material-ui/icons/HelpOutline';
import Button from '@material-ui/core/Button';
import mova from 'mova';
import Image from '../../common/Image/Image';
import { TEST_FRONTEND, TEST_FULLSTACK, WH_PROPORTION } from '../../../util/constants';
import { makeStyles } from '@material-ui/core/styles';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import StopIcon from '@material-ui/icons/Stop';
import { useHistory } from 'react-router-dom';
import DoneIcon from '@material-ui/icons/Done';

const t = mova.ns('testCard');

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 345,
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(1)
  },
  rowIcon: {
    marginRight: theme.spacing(1)
  },
  actions: {
    display: 'block'
  },
  actionRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  media: {
    position: 'relative'
  },
  imageFinished: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1
  },
  iconBackground: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    backgroundColor: '#EEC02D',
  }
}));

const testTypes = () => ({
  [TEST_FRONTEND]: {
    name: t('frontEnd'),
    imageUrl: 'choice_test/front_k6exrb',
    minutes: 60,
    tasks: 6
  },
  [TEST_FULLSTACK]: {
    name: t('fullStack'),
    imageUrl: 'choice_test/back_c9fy54',
    minutes: 90,
    tasks: 10
  }
});

const ProgrammingTestCard = ({ type, start, end, test }) => {
  const classes = useStyles();
  const history = useHistory();

  const details = testTypes()[type];

  const startTest = useCallback(() => {
    history.push('/my/programming');
  }, [history]);

  return (
    <Card className={classes.root}>
      <CardHeader title={details.name} />
      <CardMedia className={classes.media}>
        <Image imageUrl={details.imageUrl} width={345} height={345 / WH_PROPORTION} />
        {
          test && test.finish && (
            <div className={classes.imageFinished}>
              <div className={classes.iconBackground}><DoneIcon fontSize='large' /></div>
            </div>
          )
        }
      </CardMedia>
      <CardContent>
        <Typography variant='body2' color='textSecondary' component='p' className={classes.flexRow}>
          <TimeIcon className={classes.rowIcon} fontSize='small' /> {details.minutes} {t('minutes')}
        </Typography>
        <Typography variant='body2' color='textSecondary' component='p' className={classes.flexRow}>
          <QuestionIcon className={classes.rowIcon} fontSize='small' />
          {details.tasks} {t('tasks')}
        </Typography>
        {
          start && (
            <Typography variant='body2' color='textSecondary' component='p' className={classes.flexRow}>
              <PlayCircleFilledWhiteIcon className={classes.rowIcon} fontSize='small' /> {t('dateStart')}&nbsp;
              {new Date(start).toLocaleString()}
            </Typography>
          )
        }
        {
          end && (
            <Typography variant='body2' color='textSecondary' component='p' className={classes.flexRow}>
              <StopIcon className={classes.rowIcon} fontSize='small' /> {t('dateFinish')}&nbsp;
              {new Date(end).toLocaleString()}
            </Typography>
          )
        }
      </CardContent>
      <CardActions disableSpacing className={classes.actions}>
        <Button
          variant='contained'
          color='primary'
          onClick={startTest}
          fullWidth
          disabled={test && !!test.finish}
        >
          {test && !test.finish ? t('continue') : t('start')}
        </Button>
      </CardActions>
    </Card>
  );
};

ProgrammingTestCard.propTypes = {
  type: PropTypes.string.isRequired
};

ProgrammingTestCard.defaultProps = {};

export default ProgrammingTestCard;
