import React, { useRef } from 'react';
import { Form, Formik } from 'formik';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import mova from 'mova';
import FormikInput from '../../common/FormikInput/FormikInput';
import ImagePreview from '../ImagePreview/ImagePreview';
import * as yup from 'yup';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

const t = mova.ns('admin.forms');

const schema = yup.object().shape({
  name: yup.string().required(() => t('validation.required')),
  numberOfQuestions: yup.number().required().min(1, () => t('validation.min', { value: 1 })),
  minutes: yup.number().required().min(1, () => t('validation.min', { value: 1 })),
  dateStart: yup.date(),
  dateFinish: yup.date()
});

const AddEditChoiceTestModal = ({ open, handleClose, confirmAction, test }) => {
  const imageRef = useRef(null);

  const confirm = (values) => {
    confirmAction({ ...(test || {}), ...values }, imageRef);
    handleClose();
  };

  return (
    <Dialog fullWidth onClose={handleClose} aria-labelledby='simple-dialog-title' open={open}>
      <DialogTitle id='simple-dialog-title'>{t(!!test ? 'editChoiceTest' : 'addChoiceTest')}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            name: (test && test.name) || '',
            numberOfQuestions: (test && test.numberOfQuestions) || 1,
            minutes: (test && test.minutes) || 1,
            imageUrl: '',
            dateStart: (test && test.dateStart && moment(test.dateStart).format('yyyy-MM-DDThh:mm')) || '',
            dateFinish: (test && test.dateFinish && moment(test.dateFinish).format('yyyy-MM-DDThh:mm')) || '',
          }}
          validationSchema={schema}
          onSubmit={confirm}
        >
          {(props) => {
            const handleCloseImg = () => {
              props.setFieldValue('imageUrl', '');
            };

            const clearField = (field) => {
              props.setFieldValue(field, '');
            };

            return (
              <Form>
                <FormikInput
                  variant='outlined'
                  name='name'
                  label={t('namePlaceholder')}
                  placeholder={t('namePlaceholder')}
                  fullWidth
                />
                <FormikInput
                  inputProps={{min: 1}}
                  variant='outlined'
                  name='numberOfQuestions'
                  type='number'
                  label={t('noOfQuestionsPlaceholder')}
                  placeholder={t('noOfQuestionsPlaceholder')}
                  fullWidth
                />
                <FormikInput
                  inputProps={{min: 1}}
                  variant='outlined'
                  name='minutes'
                  type='number'
                  label={t('minutesPlaceholder')}
                  placeholder={t('minutesPlaceholder')}
                  fullWidth
                />
                <FormikInput
                  variant='outlined'
                  name='dateStart'
                  type='datetime-local'
                  label={props.values.dateStart && t('dateStartPlaceholder')}
                  fullWidth
                >
                  <IconButton onClick={() => clearField('dateStart')}><ClearIcon /></IconButton>
                </FormikInput>
                <FormikInput
                  variant='outlined'
                  name='dateFinish'
                  type='datetime-local'
                  label={props.values.dateFinish && t('dateFinishPlaceholder')}
                  fullWidth
                >
                  <IconButton onClick={() => clearField('dateFinish')}><ClearIcon /></IconButton>
                </FormikInput>
                <FormikInput
                  inputProps={{ accept: 'image/*' }}
                  variant='outlined'
                  name='imageUrl'
                  type='file'
                  fullWidth
                  inputRef={imageRef}
                />
                {!!props.values.imageUrl &&
                <ImagePreview inputRef={imageRef} close={handleCloseImg} alt='Test image' />}

                <Button type='submit' variant='contained' color='secondary'>{t(!!test ? 'edit' : 'add')}</Button>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditChoiceTestModal;
