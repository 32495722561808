import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import mova from 'mova';
import { ROLE_ADMIN, TEST_FULLSTACK } from '../../../util/constants';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ProgrammingTestCard from '../../../components/user/ProgrammingTestCard/ProgrammingTestCard';
import ChoiceTestCard from '../../../components/common/ChoiceTestCard/ChoiceTestCard';
import Typography from '@material-ui/core/Typography';
import { getCurrentUser } from '../../../store/actions/userActions';

const t = mova.ns('myTests');

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  heading: {
    marginBottom: theme.spacing(2)
  }
}));

const getResult = (results, test) => results.find(r => r.testId === test.id);

const MyTests = ({ user, config, getCurrentUser }) => {
  const classes = useStyles();
  const { programmingStart, programmingEnd } = config;

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  if (user.role === ROLE_ADMIN) {
    return <Redirect to='/programming' />;
  }

  const testCards = user.choiceTests.map((t) =>
    <ChoiceTestCard key={t.id} test={t} result={getResult(user.testResults, t)} />
  );

  if (user.programmingTestAssigned) {
    // testCards.push(<ProgrammingTestCard key={TEST_FRONTEND} type={TEST_FRONTEND} />);
    testCards.push(
      <ProgrammingTestCard
        key={TEST_FULLSTACK}
        type={TEST_FULLSTACK}
        start={programmingStart}
        end={programmingEnd}
        test={user.test}
      />
    );
  }

  return (
    <div className={classes.root}>
      <Typography variant='h5' className={classes.heading}>{t('list')}</Typography>
      <div className={classes.cardsContainer}>
        {testCards}
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, config }) => {
  return {
    user,
    config
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCurrentUser: () => dispatch(getCurrentUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(MyTests);
