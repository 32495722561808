import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import { Typography, withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { fade, lighten } from '@material-ui/core/styles/colorManipulator';
import Toolbar from '@material-ui/core/Toolbar';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import AdminMenu from '../../../components/admin/ProgrammingAdminMenu/ProgrammingAdminMenu';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import mova from 'mova';
import Preloader from '../../../components/common/Preloader/Preloader';
import { deleteTests, getTests, selectItem } from '../../../store/actions/testActions';
import { formatDateTime, testDuration } from '../../../util/dateTime';
import { testScoreLabel } from '../../../util/util';
import { getAllTasks } from '../../../store/actions/taskActions';

const t = mova.ns('admin');

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let TableToolbar = props => {
  const { numSelected, classes, deleteSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {
          numSelected > 0 &&
          <Typography color='inherit' variant='subtitle1'>
            {numSelected} {t('selected')}
          </Typography>
        }
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {
          numSelected > 0 &&
          <Tooltip title='Delete'>
            <IconButton aria-label='Delete' onClick={deleteSelected}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        }
      </div>
    </Toolbar>
  );
};

TableToolbar = withStyles(toolbarStyles)(TableToolbar);

const styles = theme => ({
  root: {
    padding: theme.spacing(3)
  },
  table: {
    minWidth: 1020
  },
  row: {
    cursor: 'pointer'
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  checkbox: {
    width: '100px'
  },
  button: {
    margin: theme.spacing(1)
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
    },
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center'
  }
});

const rows = (t) => [
  { id: 'checkbox', label: '' },
  { id: 'name', label: t('applicantEmail') },
  { id: 'start', label: t('startDate') },
  { id: 'finish', label: t('finishDate') },
  { id: 'duration', label: t('testDuration') },
  { id: 'result', label: t('result') }
];

class Tests extends Component {
  state = {
    search: ''
  };

  componentDidMount() {
    const { tasks, getTasks, getTests } = this.props;

    getTests();

    if (!tasks.length) {
      getTasks();
    }
  }

  render() {
    const { tests, page, rowsPerPage, loading, classes, selected, selectItem, totalItems } = this.props;
    const { search } = this.state;

    let detailedView;

    if (loading) {
      detailedView = <Preloader />;
    } else if (tests.length === 0) {
      detailedView = (
        <Fragment>
          <Typography>{t('noTests')}</Typography>
        </Fragment>
      );
    } else {
      detailedView = (
        <Fragment>
          <TableToolbar
            numSelected={selected.length}
            deleteSelected={this.deleteTests}
          />
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby='tableTitle'>
              <TableHead>
                <TableRow>
                  {rows(t).map(row => (
                    <TableCell
                      key={row.id}
                      align='left'
                      padding='none'
                    >
                      {row.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tests.map(item => {
                  return (
                    <TableRow
                      hover
                      onClick={() => this.handleRowClick(item.id)}
                      key={item.id}
                      className={classes.row}
                    >
                      <TableCell padding='checkbox' className={classes.checkbox}>
                        <Checkbox checked={selected.includes(item.id)} onClick={(e) => selectItem(e, item.id)} />
                      </TableCell>
                      <TableCell component='th' scope='row' padding='none'>
                        {item.user.email}
                      </TableCell>
                      <TableCell align='left' padding='none'>{formatDateTime(item.start)}</TableCell>
                      <TableCell align='left' padding='none'>{formatDateTime(item.finish)}</TableCell>
                      <TableCell align='left' padding='none'>{testDuration(item)}</TableCell>
                      <TableCell align='left' padding='none'>{testScoreLabel(item)}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
            component='div'
            count={totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={(event, newPage) => this.handleChangePagination(newPage, rowsPerPage)}
            onChangeRowsPerPage={(event) => this.handleChangePagination(0, event.target.value)}
          />
        </Fragment>
      );
    }

    return (
      <>
        <Paper className={classes.root}>
          <AdminMenu />
          <div className={classes.flexRow}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder={t('searchPlaceholder')}
                value={search}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                onChange={e => this.userSearched(e)}
                inputRef={(input) => this.search = input}
              />
              <Button
                variant='contained'
                color='primary'
                className={classes.button}
                onClick={this.confirmSearchUser}
              >
                {t('search')}
              </Button>
              {
                this.search && this.search.value &&
                <Button
                  className={classes.button}
                  variant='contained'
                  color='secondary'
                  onClick={this.clearSearchUser}
                >
                  {t('clear')}
                </Button>
              }
          </div>
          </div>
          {detailedView}
        </Paper>
      </>
    );
  }

  handleRowClick = (id) => {
    this.props.history.push(`/programming/tests/${id}`);
  };

  handleChangePagination = (page, rowsPerPage) => {
    const { search } = this.state;

    this.props.getTests(page, rowsPerPage, search);
  };

  userSearched = (e) => {
    this.setState({ search: e.target.value });
  };

  confirmSearchUser = () => {
    const { page, rowsPerPage, getTests } = this.props;
    const { search } = this.state;

    getTests(page, rowsPerPage, search);
  };

  clearSearchUser = () => {
    this.setState({ search: '' }, this.confirmSearchUser);
  };

  deleteTests = () => {
    const { selected, page, rowsPerPage, deleteSelected } = this.props;

    deleteSelected(selected, page, rowsPerPage);
    this.setState({ search: '' });
  };
}

const mapStateToProps = ({ tests, tasks }) => {
  return {
    tests: tests.content,
    page: tests.page,
    rowsPerPage: tests.size,
    totalItems: tests.totalElements,
    loading: tests.fetching || tasks.loading,
    selected: tests.selected,
    tasks: tasks.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTests: (page, rowsPerPage, email) => dispatch(getTests(page, rowsPerPage, email)),
    selectItem: (e, id) => dispatch(selectItem(e, id)),
    deleteSelected: (selected, page, rowsPerPage) => dispatch(deleteTests(selected, page, rowsPerPage)),
    getTasks: () => dispatch(getAllTasks())
  };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Tests));
