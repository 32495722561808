import { toastr } from 'react-redux-toastr';
import axios from 'axios';

const METHOD_GET = 'get';
const METHOD_POST = 'post';
const METHOD_PUT = 'put';
const METHOD_DELETE = 'delete';
const TOKEN = 'token';

export class FetchData {
  _token = localStorage.getItem(TOKEN);

  setToken(token) {
    this._token = token;
    localStorage.setItem(TOKEN, token);
  }

  resetToken() {
    this._token = null;
    localStorage.removeItem(TOKEN);
  }

  get(url, requestParams, headers) {
    return this.makeRequest(url, METHOD_GET, null, requestParams, headers);
  }

  post(url, body, requestParams, headers) {
    return this.makeRequest(url, METHOD_POST, body, requestParams, headers);
  }

  put(url, body, requestParams, headers) {
    return this.makeRequest(url, METHOD_PUT, body, requestParams, headers);
  }

  deleteApi(url, requestParams, headers) {
    return this.makeRequest(url, METHOD_DELETE, null, requestParams, headers);
  }

  makeRequest(url, method, body, reqParams, headers) {
    const appliedHeaders = headers || {};

    if (this._token) {
      appliedHeaders.authorization = `Bearer ${this._token}`
    }

    const requestParams = {
      method: method || METHOD_GET,
      data: body,
      params: {
        ...(reqParams || {})
      },
      headers: appliedHeaders
    };

    if (method === METHOD_POST || method === METHOD_PUT) {
      requestParams.headers['Content-Type'] = 'application/json';
    }

    return this.sendRequest(url, requestParams);
  }

  sendRequest(url, requestParams) {
    return new Promise((resolve, reject) => {
      axios(url, requestParams)
        .then(result => resolve(result.data))
        .catch(reason => {
          this.requestFailed(reason);
          reject(reason);
        });
    });
  }

  requestFailed(reason) {
    const response = reason.response;

    const header = (response && response.statusText) || 'Error';
    const message = (response && response.data) || 'An error has occurred';
    toastr.error(header, message);
  }
}

const api = new FetchData();

export default api;
