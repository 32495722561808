import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Toolbar from '@material-ui/core/Toolbar';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import {
  addChoiceTestUsers,
  deleteChoiceTestUsers,
  getChoiceTestUsers,
  selectChoiceTestUser
} from '../../../store/actions/choiceTestActions';
import Button from '@material-ui/core/Button';
import { toastr } from 'react-redux-toastr';
import TextField from '@material-ui/core/TextField';
import AdminMenu from '../../../components/admin/ChoiceAdminMenu/ChoiceAdminMenu';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import { fade, makeStyles } from '@material-ui/core/styles';
import mova from 'mova';
import Preloader from '../../../components/common/Preloader/Preloader';
import ChangeNameModal from '../../../components/admin/ChangeNameModal/ChangeNameModal';
import AddUserModal from '../../../components/admin/AddUserModal/AddUserModal';
import { useCopyToClipboard } from 'react-use';
import { updateChoiceTestUsersPagination } from '../../../store/actions/choiceTestActions';
import { updateUserName } from '../../../store/actions/userActions';

const t = mova.ns('admin');

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
}));

const TableToolbar = props => {
  const { numSelected, deleteSelected } = props;
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {
          numSelected > 0 &&
          <Typography color='inherit' variant='subtitle1'>
            {numSelected} {t('selected')}
          </Typography>
        }
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {
          numSelected > 0 &&
          <Tooltip title={t('delete')}>
            <IconButton aria-label='Delete' onClick={deleteSelected}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        }
      </div>
    </Toolbar>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  table: {
    minWidth: 1020
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  checkbox: {
    width: '100px'
  },
  button: {
    margin: theme.spacing(1)
  },
  copyButton: {
    marginLeft: theme.spacing(2)
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
    },
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const rows = (t) => [
  { id: 'checkbox', label: '' },
  { id: 'name', label: t('applicantEmail') },
  { id: 'password', label: t('password') }
];

const ChoiceTestUsers = (
  {
    loading, match, history, users, getUsers, addUsers, updateUserName, page, rowsPerPage, selected, deleteSelected,
    selectItem, totalItems, updatePagination
  }
) => {
  const [addingUsers, setAddingUsers] = useState(false);
  const [userToUpdate, setUserToUpdate] = useState(null);
  const [search, setSearch] = useState('');
  const [copyState, copyToClipboard] = useCopyToClipboard();
  const { testId } = match.params;
  const classes = useStyles();
  const searchInputRef = useRef();

  useEffect(() => {
    getUsers(testId, page, rowsPerPage, search);
  }, [testId, page, rowsPerPage, search, getUsers]);

  useEffect(() => {
    if (copyState.value) {
      toastr.success(t('passwordCopied'));
    }
  }, [copyState]);

  const copyPassword = (user) => {
    copyToClipboard(user.uiPassword);
  };

  const openAddUserModal = () => {
    setAddingUsers(true);
  };

  const closeAddUserModal = () => {
    setAddingUsers(false);
  };

  const openChangeUsernameModal = (user) => {
    setUserToUpdate(user);
  };

  const closeChangeUsernameModal = () => {
    setUserToUpdate(null);
  };

  const confirmAddUsers = (users, password) => {
    addUsers(testId, users, password, page, rowsPerPage);
  };

  const confirmChangeName = (user, newName) => {
    updateUserName(user, newName, page, rowsPerPage, testId);
  };

  const confirmSearchUser = () => {
    setSearch(searchInputRef.current.value);
  };

  const clearSearchUser = () => {
    searchInputRef.current.value = '';
    setSearch('');
  };

  const deleteUsers = () => {
    deleteSelected(testId, selected, page, rowsPerPage);
    setSearch('');
    searchInputRef.current.value = '';
  };

  let detailedView;

  if (loading || users === undefined) {
    detailedView = <Preloader />;
  } else if (users.length === 0) {
    detailedView = <Typography>{t('noUsers')}</Typography>;
  } else {
    detailedView = (
      <Fragment>
        <TableToolbar
          numSelected={selected.length}
          deleteSelected={deleteUsers}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby='tableTitle'>
            <TableHead>
              <TableRow>
                {rows(t).map(row => (
                  <TableCell
                    key={row.id}
                    align='left'
                    padding='none'
                  >
                    {row.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map(user => {
                return (
                  <TableRow
                    hover
                    key={user.id}
                    className={classes.row}
                  >
                    <TableCell padding='checkbox' className={classes.checkbox}>
                      <Checkbox checked={selected.includes(user.id)} onClick={(e) => selectItem(e, user.id)} />
                    </TableCell>
                    <TableCell component='th' scope='row' padding='none'>
                      {user.email}
                      <IconButton aria-label="edit" size="small" onClick={() => openChangeUsernameModal(user)}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell scope='row' padding='none'>
                      <TextField value={user.uiPassword} margin="none" />
                      <Button
                        onClick={() => copyPassword(user)}
                        className={classes.copyButton}
                        variant='contained'
                        size='small'
                        color='primary'
                      >
                        {t('copy')}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
          component='div'
          count={totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={(event, newPage) => updatePagination(newPage, rowsPerPage)}
          onChangeRowsPerPage={(event) => updatePagination(0, event.target.value)}
        />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Paper className={classes.root}>
        <AddUserModal open={!!addingUsers} handleClose={closeAddUserModal} addUsers={confirmAddUsers} />
        <ChangeNameModal open={!!userToUpdate} handleClose={closeChangeUsernameModal} user={userToUpdate}
                         confirm={confirmChangeName} />
        <AdminMenu />
        <div className={classes.flexRow}>
          <Button
            variant='contained'
            color='primary'
            onClick={openAddUserModal}
            className={classes.button}
          >
            {t('addUsers')}
          </Button>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder={t('searchPlaceholder')}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              inputRef={searchInputRef}
            />
            <Button
              variant='contained'
              color='primary'
              className={classes.button}
              onClick={confirmSearchUser}
            >
              {t('search')}
            </Button>
            {
              !!search &&
              <Button
                className={classes.button}
                variant='contained'
                color='secondary'
                onClick={clearSearchUser}
              >
                {t('clear')}
              </Button>
            }
            <Button
              variant='outlined'
              color='primary'
              className={classes.button}
              onClick={() => alert('Not implemented yet')}
            >
              {t('exportToExcel')}
            </Button>
          </div>
        </div>
        {detailedView}
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = ({ choiceTest }) => {
  return {
    users: choiceTest.users.content,
    loading: choiceTest.users.fetching,
    page: choiceTest.users.page,
    rowsPerPage: choiceTest.users.size,
    totalItems: choiceTest.users.totalElements,
    selected: choiceTest.users.selected,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUsers: (testId, page, rowsPerPage, email) => dispatch(getChoiceTestUsers(testId, page, rowsPerPage, email)),
  addUsers: (testId, users, password, page, rowsPerPage) => dispatch(addChoiceTestUsers(testId, users, password, page, rowsPerPage)),
  selectItem: (e, id) => dispatch(selectChoiceTestUser(e, id)),
  deleteSelected: (testId, selected, page, rowsPerPage) => dispatch(deleteChoiceTestUsers(testId, selected, page, rowsPerPage)),
  updateUserName: (user, newName, page, rowsPerPage, testId) => dispatch(updateUserName(user, newName, page, rowsPerPage, testId)),
  updatePagination: (page, rowsPerPage) => dispatch(updateChoiceTestUsersPagination(page, rowsPerPage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChoiceTestUsers);
