import Actions from '../actions/actionTypes';

const initialState = {
  content: [],
  totalElements: 0,
  totalPages: 0,
  page: 0,
  size: 10,
  fetching: true,
  selected: []
};

function users(state = initialState, action) {
  switch (action.type) {
    case Actions.SAVE_USERS:
      return { ...state, ...action.users };
    case Actions.USERS_FETCHING:
      return { ...state, fetching: action.payload };
    case Actions.USER_SELECTED:
      if (state.selected.includes(action.payload)) {
        return { ...state, selected: state.selected.filter(item => item !== action.payload) };
      } else {
        return { ...state, selected: [...state.selected, action.payload] };
      }
    case Actions.USERS_DELETED:
      return { ...state, selected: [] };
    case Actions.CHANGE_USER_PAGE:
      return { ...state, page: action.page };
    case Actions.CHANGE_USER_PAGE_SIZE:
      return { ...state, size: action.rowsPerPage };
    default:
      return state;
  }
}

export default users;
