import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  imgPrevBlock: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  closeIcon: {
    alignSelf: 'flex-start'
  }
}));

function ImagePreview({ inputRef, deleteImg, alt }) {
  const classes = useStyles();

  const fileSrc = URL.createObjectURL(inputRef.current.files[0]);

  return (
    <div className={classes.imgPrevBlock}>
      <img src={fileSrc} width='200px' alt={alt} />
      <IconButton aria-label="close" className={classes.closeIcon} onClick={deleteImg}>
        <CloseIcon />
      </IconButton>
    </div>
  );
}

export default ImagePreview;
