import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Toolbar from '@material-ui/core/Toolbar';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import {
  getChoiceTestQuestions,
  deleteChoiceTestQuestions,
  updateChoiceTestQuestionsPagination,
  addEditQuestion
} from '../../../store/actions/choiceTestActions';
import Button from '@material-ui/core/Button';
import AdminMenu from '../../../components/admin/ChoiceAdminMenu/ChoiceAdminMenu';
import { makeStyles } from '@material-ui/core/styles';
import mova from 'mova';
import Preloader from '../../../components/common/Preloader/Preloader';
import AddEditQuestionModal from '../../../components/admin/AddEditQuestionModal/AddEditQuestionModal';
import QuestionTableRow from './components/QuestionTableRow';

const t = mova.ns('admin');

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
}));

const TableToolbar = props => {
  const { numSelected, deleteSelected } = props;
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {
          numSelected > 0 &&
          <Typography color='inherit' variant='subtitle1'>
            {numSelected} {t('selected')}
          </Typography>
        }
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {
          numSelected > 0 &&
          <Tooltip title={t('delete')}>
            <IconButton aria-label='Delete' onClick={deleteSelected}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        }
      </div>
    </Toolbar>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  table: {
    minWidth: 1020
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  button: {
    margin: theme.spacing(1)
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const rows = (t) => [
  { id: 'select', label: '' },
  { id: 'text', label: t('question') },
  { id: 'image', label: t('image') },
  { id: 'edit', label: '' },
  { id: 'open', label: '' }
];

const ChoiceTestQuestions = (
  {
    loading, match, history, questions, getQuestions, addQuestion, addEditOption, page, rowsPerPage, selected,
    deleteSelected, deleteOption, totalItems, updatePagination
  }
) => {
  const [addingQuestion, setAddingQuestion] = useState(false);
  const { testId } = match.params;
  const classes = useStyles();

  useEffect(() => {
    getQuestions(testId, page, rowsPerPage);
  }, [testId, page, rowsPerPage, getQuestions]);

  const openAddQuestionModal = useCallback(() => {
    setAddingQuestion(true);
  }, []);

  const closeAddQuestionModal = useCallback(() => {
    setAddingQuestion(false);
  }, []);

  const confirmAddQuestion = useCallback((question, imageRef) => {
    addQuestion(testId, question, imageRef, page, rowsPerPage);
  }, [testId, page, rowsPerPage, addQuestion]);

  const deleteQuestions = useCallback(() => {
    deleteSelected(testId, selected, page, rowsPerPage);
  }, [testId, selected, page, rowsPerPage, deleteSelected]);

  let detailedView;

  if (loading) {
    detailedView = <Preloader />;
  } else if (questions.length === 0) {
    detailedView = <Typography>{t('noQuestions')}</Typography>;
  } else {
    detailedView = (
      <Fragment>
        <TableToolbar
          numSelected={selected.length}
          deleteSelected={deleteQuestions}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby='tableTitle'>
            <TableHead>
              <TableRow>
                {rows(t).map(row => (
                  <TableCell key={row.id} align='left' padding='none'>
                    {row.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {questions.map(q => <QuestionTableRow key={q.id} question={q} testId={testId} />)}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 500]}
          component='div'
          count={totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={(event, newPage) => updatePagination(newPage, rowsPerPage)}
          onChangeRowsPerPage={(event) => updatePagination(0, event.target.value)}
        />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Paper className={classes.root}>
        <AddEditQuestionModal
          open={!!addingQuestion}
          handleClose={closeAddQuestionModal}
          confirmAction={confirmAddQuestion}
        />
        <AdminMenu />
        <div className={classes.flexRow}>
          <Button variant='contained' color='primary' onClick={openAddQuestionModal} className={classes.button}>
            {t('addQuestion')}
          </Button>
        </div>
        {detailedView}
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = ({ choiceTest }) => {
  return {
    questions: choiceTest.questions.content,
    loading: choiceTest.questions.fetching,
    page: choiceTest.questions.page,
    rowsPerPage: choiceTest.questions.size,
    totalItems: choiceTest.questions.totalElements,
    selected: choiceTest.questions.selected,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getQuestions: (testId, page, rowsPerPage) => dispatch(getChoiceTestQuestions(testId, page, rowsPerPage)),
  addQuestion: (testId, question, imageRef, page, rowsPerPage) => dispatch(addEditQuestion(testId, question, imageRef, page, rowsPerPage)),
  deleteSelected: (testId, selected, page, rowsPerPage) => dispatch(deleteChoiceTestQuestions(testId, selected, page, rowsPerPage)),
  updatePagination: (page, rowsPerPage) => dispatch(updateChoiceTestQuestionsPagination(page, rowsPerPage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChoiceTestQuestions);
