import React, { memo } from 'react';
import TextField from '@material-ui/core/TextField';
import { useField } from 'formik';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  textField: {
    margin: theme.spacing(1, 0),
  }
}));


const FormikInput = ({name, children, ...rest}) => {
  const [field, meta] = useField(name);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TextField
        {...field}
        {...rest}
        error={meta.touched && !!meta.error}
        helperText={meta.error}
        className={classes.textField}
      />
      {children}
    </div>
  );

};

export default memo(FormikInput);
