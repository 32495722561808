const Actions = {
  SAVE_TESTS: 'SAVE_TESTS',
  SAVE_TEST: 'SAVE_TEST',
  TEST_SELECTED: 'TEST_SELECTED',
  TESTS_DELETED: 'TESTS_DELETED',
  TESTS_FETCHING: 'TESTS_FETCHING',
  INITIAL_DATA_LOADING: 'INITIAL_DATA_LOADING',
  CHANGE_PAGE: 'CHANGE_PAGE',
  CHANGE_PAGE_SIZE: 'CHANGE_PAGE_SIZE',
  MODAL_OPENED: 'MODAL_OPENED',
  MODAL_CLOSED: 'MODAL_CLOSED',
  TEST_FOR_UPDATE: 'TEST_FOR_UPDATE',
  TASKS_INFO_LOADNIG: 'TASKS_INFO_LOADING',
  TASKS_INFO: 'TASKS_INFO',
  USER_LOADED: 'USER_LOADED',
  SET_ACTIVE_TASK: 'SET_ACTIVE_TASK',
  UPDATE_ACTIVE_TASK_CODE: 'UPDATE_ACTIVE_TASK_CODE',
  RESET_CODE: 'RESET_CODE',
  INCREMENT_CODE_VERSION: 'INCREMENT_CODE_VERSION',
  SET_COMPILATION_RESULT: 'SET_COMPILATION_RESULT',
  SET_SERVER_RESPONSE: 'SET_SERVER_RESPONSE',
  SET_COMPLETED_TASKS: 'SET_COMPLETED_TASKS',
  TASK_COMPLETED: 'TASK_COMPLETED',
  USERS_FETCHING: 'USERS_FETCHING',
  SAVE_USERS: 'SAVE_USERS',
  CHANGE_USER_PAGE: 'CHANGE_USER_PAGE',
  CHANGE_USER_PAGE_SIZE: 'CHANGE_USER_PAGE_SIZE',
  USER_SELECTED: 'USER_SELECTED',
  USERS_DELETED: 'USERS_DELETED',
  CONFIG_FETCHED: 'CONFIG_FETCHED',
  SAVE_CHOICE_TESTS: 'SAVE_CHOICE_TESTS',
  NEW_CHOICE_TEST: 'NEW_CHOICE_TEST',
  UPDATE_CHOICE_TEST: 'UPDATE_CHOICE_TEST',
  CHOICE_TESTS_FETCHING: 'CHOICE_TESTS_FETCHING',
  CHOICE_TEST_DELETED: 'CHOICE_TEST_DELETED',

  SAVE_CHOICE_TEST: 'SAVE_CHOICE_TEST',
  CHOICE_TEST_FETCHING: 'CHOICE_TEST_FETCHING',

  SAVE_CHOICE_TEST_USERS: 'SAVE_CHOICE_TEST_USERS',
  CHOICE_TEST_USERS_FETCHING: 'CHOICE_TEST_USERS_FETCHING',
  CHANGE_CHOICE_TEST_USERS_PAGE: 'CHANGE_CHOICE_TEST_USERS_PAGE',
  CHANGE_CHOICE_TEST_USERS_PAGE_SIZE: 'CHANGE_CHOICE_TEST_USERS_PAGE_SIZE',
  CHOICE_TEST_USER_SELECTED: 'CHOICE_TEST_USER_SELECTED',
  CHOICE_TEST_USERS_DELETED: 'CHOICE_TEST_USERS_DELETED',

  SAVE_CHOICE_TEST_QUESTIONS: 'SAVE_CHOICE_TEST_QUESTIONS',
  CHOICE_TEST_QUESTIONS_FETCHING: 'CHOICE_TEST_QUESTIONS_FETCHING',
  CHANGE_CHOICE_TEST_QUESTIONS_PAGE: 'CHANGE_CHOICE_TEST_QUESTIONS_PAGE',
  CHANGE_CHOICE_TEST_QUESTIONS_PAGE_SIZE: 'CHANGE_CHOICE_TEST_QUESTIONS_PAGE_SIZE',
  CHOICE_TEST_QUESTION_SELECTED: 'CHOICE_TEST_QUESTION_SELECTED',
  CHOICE_TEST_QUESTIONS_DELETED: 'CHOICE_TEST_QUESTIONS_DELETED',
  UPDATE_CHOICE_TEST_QUESTION: 'UPDATE_CHOICE_TEST_QUESTION',
  NEW_CHOICE_TEST_OPTION: 'NEW_CHOICE_TEST_OPTION',
  UPDATE_CHOICE_TEST_OPTIONS: 'UPDATE_CHOICE_TEST_OPTIONS',

  SAVE_CHOICE_TEST_RESULTS: 'SAVE_CHOICE_TEST_RESULTS',
  CHOICE_TEST_RESULTS_FETCHING: 'CHOICE_TEST_RESULTS_FETCHING',
  CHANGE_CHOICE_TEST_RESULTS_PAGE: 'CHANGE_CHOICE_TEST_RESULTS_PAGE',
  CHANGE_CHOICE_TEST_RESULTS_PAGE_SIZE: 'CHANGE_CHOICE_TEST_RESULTS_PAGE_SIZE',
  CHOICE_TEST_RESULT_SELECTED: 'CHOICE_TEST_RESULT_SELECTED',
  CHOICE_TEST_RESULTS_DELETED: 'CHOICE_TEST_RESULTS_DELETED',
};

export default Actions;
