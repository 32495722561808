import api from '../../services/api';
import Actions from './actionTypes';
import { toastr } from 'react-redux-toastr';
import { getChoiceTestUsers } from './choiceTestActions';

export const logIn = (email, password) => dispatch => {
  return api.post('/api/auth/login', { email, password })
    .then(token => {
      api.setToken(token);
      dispatch(getCurrentUser());
    });
};

export const getCurrentUser = () => dispatch => {
  return api.get('/api/users/current')
    .then(user => {
      if (user) {
        dispatch({ type: Actions.USER_LOADED, payload: user });
        dispatch({ type: Actions.SET_COMPLETED_TASKS, payload: calculateCompletedTasks(user) });
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        api.resetToken();
        window.location.reload()
      }
    });
};

const calculateCompletedTasks = (user) => (user && user.test && user.test.solutions && user.test.solutions
  .filter(item => item.testsCompleted === item.testsTotal)
  .map(item => item.taskNumber)) || [];

export const logoutUser = () => () => {
  api.resetToken();
  window.location.reload();
};

export const getUsers = (page, rowsPerPage, email) => dispatch => {
  const requestParams = {};

  page !== undefined && (requestParams.page = page);
  rowsPerPage !== undefined && (requestParams.size = rowsPerPage);
  email !== undefined && email !== '' && (requestParams.email = email);

  dispatch({ type: Actions.USERS_FETCHING, payload: true });
  return api.get('/api/users', requestParams)
    .then(users => {
      dispatch({ type: Actions.SAVE_USERS, users });

      page !== undefined && dispatch({ type: Actions.CHANGE_USER_PAGE, page });
      rowsPerPage !== undefined && dispatch({ type: Actions.CHANGE_USER_PAGE_SIZE, rowsPerPage });

      dispatch({ type: Actions.USERS_FETCHING, payload: false });
    });
};

export const selectUserItem = (e, id) => dispatch => {
  e.stopPropagation();
  dispatch({ type: Actions.USER_SELECTED, payload: id });
};

export const deleteUsers = (selected, page, rowsPerPage) => dispatch => {
  Promise.all(selected.map(id => api.deleteApi(`/api/users/${id}`)))
    .then(() => {
      dispatch({ type: Actions.USERS_DELETED });
      dispatch(getUsers(page, rowsPerPage));
    });
};

export const addUsers = (users, password, page, rowsPerPage) => dispatch => {
  return api.post('/api/users', { emails: users, password })
    .then(res => {
      if (res) {
        toastr.error('Users that were not added (emails already exist in the system): ' + res);
      }
      dispatch(getUsers(page, rowsPerPage));
    });
};

export const updateUserName = (user, newName, page, rowsPerPage, testId) => dispatch => {
  return api.put('/api/users', { user, email: newName })
    .then(res => {
      if (res) {
        toastr.error('User with this email already exists in the system: ' + res);
      } else {
        if (testId) {
          dispatch(getChoiceTestUsers(testId, page, rowsPerPage));
        } else {
          dispatch(getUsers(page, rowsPerPage));
        }
      }
    });
};

export const exportToExcel = () => () => {
  fetch('/api/excel/export', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
    .then((response) => response.blob())
    .then((blob) => {
      var _url = window.URL.createObjectURL(blob);
      window.open(_url, "_blank").focus();
    }).catch((err) => {
    console.log(err);
  });
  // window.open(`/api/excel/export?Authorization=Bearer ${localStorage.getItem('token')}`);
};
