import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { fade, lighten } from '@material-ui/core/styles/colorManipulator';
import Toolbar from '@material-ui/core/Toolbar';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import AdminMenu from '../../../components/admin/ChoiceAdminMenu/ChoiceAdminMenu';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import mova from 'mova';
import Preloader from '../../../components/common/Preloader/Preloader';
import { choiceTestDuration, formatDateTime } from '../../../util/dateTime';
import { choiceTestScoreLabel } from '../../../util/util';
import { makeStyles } from '@material-ui/core/styles';
import {
  deleteChoiceTestResults,
  getChoiceTestResults,
  selectChoiceTestResult,
  updateChoiceTestResultsPagination
} from '../../../store/actions/choiceTestActions';

const t = mova.ns('admin');

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
}));

const TableToolbar = props => {
  const { numSelected, deleteSelected } = props;
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {
          numSelected > 0 &&
          <Typography color='inherit' variant='subtitle1'>
            {numSelected} {t('selected')}
          </Typography>
        }
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {
          numSelected > 0 &&
          <Tooltip title='Delete'>
            <IconButton aria-label='Delete' onClick={deleteSelected}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        }
      </div>
    </Toolbar>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  table: {
    minWidth: 1020
  },
  row: {
    cursor: 'pointer'
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  checkbox: {
    width: '100px'
  },
  button: {
    margin: theme.spacing(1)
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
    },
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const rows = (t) => [
  { id: 'checkbox', label: '' },
  { id: 'name', label: t('applicantEmail') },
  { id: 'start', label: t('startDate') },
  { id: 'finish', label: t('finishDate') },
  { id: 'duration', label: t('testDuration') },
  { id: 'result', label: t('result') }
];

const ChoiceTestResults = (
  {
    history, match, tests, getTests, page, rowsPerPage, loading, selected, selectItem, totalItems, updatePagination,
    deleteSelected
  }
) => {
  const [search, setSearch] = useState('');
  const classes = useStyles();
  const { testId } = match.params;
  const searchInputRef = useRef();

  useEffect(() => {
    getTests(testId, page, rowsPerPage, search);
  }, [getTests, testId, page, rowsPerPage, search]);

  const handleRowClick = (id) => {
    history.push(`/choice/${testId}/${id}/details`);
  };

  const confirmSearchUser = () => {
    setSearch(searchInputRef.current.value);
  };

  const clearSearchUser = () => {
    searchInputRef.current.value = '';
    setSearch('');
  };

  const deleteTests = () => {
    deleteSelected(testId, selected, page, rowsPerPage);
    searchInputRef.current.value = '';
    setSearch('');
  };

  let detailedView;

  if (loading) {
    detailedView = <Preloader />;
  } else if (tests.length === 0) {
    detailedView = (
      <Fragment>
        <Typography>{t('noTests')}</Typography>
      </Fragment>
    );
  } else {
    detailedView = (
      <Fragment>
        <TableToolbar
          numSelected={selected.length}
          deleteSelected={deleteTests}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby='tableTitle'>
            <TableHead>
              <TableRow>
                {rows(t).map(row => (
                  <TableCell
                    key={row.id}
                    align='left'
                    padding='none'
                  >
                    {row.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tests.map(item => {
                return (
                  <TableRow
                    hover
                    onClick={() => handleRowClick(item.id)}
                    key={item.id}
                    className={classes.row}
                  >
                    <TableCell padding='checkbox' className={classes.checkbox}>
                      <Checkbox checked={selected.includes(item.id)} onClick={(e) => selectItem(e, item.id)} />
                    </TableCell>
                    <TableCell component='th' scope='row' padding='none'>
                      {item.user.email}
                    </TableCell>
                    <TableCell align='left' padding='none'>{formatDateTime(item.createdDate)}</TableCell>
                    <TableCell align='left' padding='none'>{formatDateTime(item.finishedAt)}</TableCell>
                    <TableCell align='left' padding='none'>{choiceTestDuration(item)}</TableCell>
                    <TableCell align='left' padding='none'>{choiceTestScoreLabel(item)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
          component='div'
          count={totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={(event, newPage) => updatePagination(newPage, rowsPerPage)}
          onChangeRowsPerPage={(event) => updatePagination(0, event.target.value)}
        />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Paper className={classes.root}>
        <AdminMenu />
        <div className={classes.flexRow}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder={t('searchPlaceholder')}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              inputRef={searchInputRef}
            />
            <Button
              variant='contained'
              color='primary'
              className={classes.button}
              onClick={confirmSearchUser}
            >
              {t('search')}
            </Button>
            {
              !!search &&
              <Button
                className={classes.button}
                variant='contained'
                color='secondary'
                onClick={clearSearchUser}
              >
                {t('clear')}
              </Button>
            }
          </div>
        </div>
        {detailedView}
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = ({ choiceTest }) => {
  return {
    tests: choiceTest.results.content,
    loading: choiceTest.results.fetching,
    page: choiceTest.results.page,
    rowsPerPage: choiceTest.results.size,
    totalItems: choiceTest.results.totalElements,
    selected: choiceTest.results.selected
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTests: (testId, page, rowsPerPage, email) => dispatch(getChoiceTestResults(testId, page, rowsPerPage, email)),
    selectItem: (e, id) => dispatch(selectChoiceTestResult(e, id)),
    deleteSelected: (testId, selected, page, rowsPerPage) => dispatch(deleteChoiceTestResults(testId, selected, page, rowsPerPage)),
    updatePagination: (page, rowsPerPage) => dispatch(updateChoiceTestResultsPagination(page, rowsPerPage)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoiceTestResults);
