import api from '../../services/api';
import Actions from './actionTypes';
import { getCurrentUser } from './userActions';

export const startTest = () => dispatch => {
  return api.post('/api/tests/start')
    .then(() => dispatch(getCurrentUser()));
};

export const finishTest = () => dispatch => {
  return api.post('/api/tests/finish')
    .then(() => dispatch(getCurrentUser()));
};

export const getTests = (page, rowsPerPage, email) => dispatch => {
  const requestParams = {};

  page !== undefined && (requestParams.page = page);
  rowsPerPage !== undefined && (requestParams.size = rowsPerPage);
  email !== undefined && email !== '' && (requestParams.email = email);

  dispatch({ type: Actions.TESTS_FETCHING, payload: true });
  return api.get('/api/tests', requestParams)
    .then(tests => {
      dispatch({ type: Actions.SAVE_TESTS, tests });

      page !== undefined && dispatch({ type: Actions.CHANGE_PAGE, page });
      rowsPerPage !== undefined && dispatch({ type: Actions.CHANGE_PAGE_SIZE, rowsPerPage });

      dispatch({ type: Actions.TESTS_FETCHING, payload: false });
    });
};

export const getTest = (testId) => dispatch => {
  return api.get(`/api/tests/${testId}`)
    .then(test => dispatch({ type: Actions.SAVE_TEST, test }));
};

export const selectItem = (e, id) => dispatch => {
  e.stopPropagation();
  dispatch({ type: Actions.TEST_SELECTED, payload: id });
};

export const deleteTests = (selected, page, rowsPerPage) => dispatch => {
  Promise.all(selected.map(id => api.deleteApi(`/api/tests/${id}`)))
    .then(() => {
      dispatch({ type: Actions.TESTS_DELETED });
      dispatch(getTests(page, rowsPerPage));
    });
};

export const confirmChangeName = (test, newName) => () => {
  api.put(`/api/tests/${test.id}/name`, newName)
    .then(() => window.location.reload());
};
