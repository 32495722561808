import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import mova from 'mova';
import { makeStyles } from '@material-ui/core/styles';
import { answerQuestion, finishChoiceTest, getMyTestResult } from '../../../store/actions/choiceTestActions';
import { Redirect } from 'react-router-dom';
import ThankYou from '../ThankYou/ThankYou';
import Preloader from '../../../components/common/Preloader/Preloader';
import Timer from '../../../components/user/Timer/Timer';
import { ROLE_ADMIN } from '../../../util/constants';
import Typography from '@material-ui/core/Typography';
import FinishTestModal from '../FinishTestModal/FinishTestModal';
import Button from '@material-ui/core/Button';
import QuestionTask from './components/QuestionTask/QuestionTask';
import QuestionAnswer from './components/QuestionAnswer/QuestionAnswer';
import Container from '@material-ui/core/Container';

const t = mova.ns('choiceTest');

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(3),
    borderBottom: '1px solid black'
  }
}));

const ChoiceTest = ({ user, test, myTest, loading, getChoiceTestResult, answerQuestion, finishTest }) => {
  const classes = useStyles();
  const [finishTestOpen, setFinishTestOpen] = useState(false);

  useEffect(() => {
    if (test && (!myTest || myTest.testId !== test.id) && !loading) {
      getChoiceTestResult(test.id);
    }
  });

  const openFinishTestModal = useCallback(() => setFinishTestOpen(true), []);
  const closeFinishTestModal = useCallback(() => setFinishTestOpen(false), []);
  const confirmFinishTest = useCallback(() => finishTest(myTest.id), [myTest, finishTest]);

  if (!test) {
    return <Redirect to='/' />;
  }

  if (user && user.role === ROLE_ADMIN) {
    return <Redirect to='/programming' />;
  }

  if (!myTest || loading) {
    return <Preloader />;
  }

  if (myTest.finished || myTest.timeLeft <= 0) {
    return <ThankYou />;
  }

  return (
    <div className={classes.root}>
      <FinishTestModal open={finishTestOpen} handleClose={closeFinishTestModal} finish={confirmFinishTest} />
      <Container maxWidth='lg'>
        <header className={classes.header}>
          <Typography variant='h5'>
            {t('question')} {myTest.questionsAnswered + 1} {t('of')} {myTest.numberOfQuestions}
          </Typography>
          <Timer
            millis={myTest.timeLeft}
            className={classes.timer}
            onFinish={confirmFinishTest}
          />
          <Button variant='contained' color='secondary' className={classes.submit} onClick={openFinishTestModal}>
            {t('finishButton')}
          </Button>
        </header>
        <main>
          <QuestionTask question={myTest.currentQuestion} />
          <QuestionAnswer question={myTest.currentQuestion} answerQuestion={answerQuestion} resultId={myTest.id} />
        </main>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ user, choiceTest }, props) => {
  const { testId } = props.match.params;

  const test = user.choiceTests.find(t => t.id === parseInt(testId));

  return {
    user,
    test,
    myTest: choiceTest.test,
    loading: choiceTest.fetching
  };
};

const mapDispatchToProps = dispatch => ({
  getChoiceTestResult: (testId) => dispatch(getMyTestResult(testId)),
  answerQuestion: (resultId, answers) => dispatch(answerQuestion(resultId, answers)),
  finishTest: (resultId) => dispatch(finishChoiceTest(resultId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChoiceTest);
