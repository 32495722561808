import React, { useRef } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import mova from 'mova';

const t = mova.ns('admin');

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));

function ChangeNameModal(props) {
  const { open, handleClose, user, confirm } = props;
  const classes = useStyles();
  const inputRef = useRef(null);

  if (!user) {
    return null;
  }

  return (
    <Dialog
      className={classes.root}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">{t('editUserEmail')}</DialogTitle>
      <DialogContent>
        <TextField
          id='standard-dense'
          label={t('newUserName')}
          fullWidth={true}
          margin='dense'
          inputRef={inputRef}
          defaultValue={user.email}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            confirm(user, inputRef.current.value);
            handleClose();
          }}
        >
          {t('changeName')}
        </Button>
        <Button
          variant='contained'
          color='default'
          onClick={handleClose}
        >
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ChangeNameModal;
