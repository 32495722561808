import React, { Component } from 'react';
import { Collapse, withStyles } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { formatDateTime } from '../../../util/dateTime';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
  rowEmpty: {
    height: 0,
    padding: 0,
    border: 'none'
  },
  expand: {
    width: '70px'
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
});

class RowItem extends Component {
  state = {
    expanded: false
  };

  render() {
    const { item, classes } = this.props;
    const { expanded } = this.state;

    return (
      <>
        <TableRow
          hover
          key={item.id}
          onClick={this.handleRowExpand}
        >
          <TableCell padding='none' component='th' scope='row'>{item.task}</TableCell>
          <TableCell padding='none' align='left'>{formatDateTime(item.createdDate)}</TableCell>
          <TableCell padding='none' align='left'>{item.testsCompleted}</TableCell>
          <TableCell padding='none' align='left'>{item.testsTotal}</TableCell>
          <TableCell padding='none' align='left'>{item.score}</TableCell>
          <TableCell padding='none' align='center' className={classes.expand}>
            <IconButton className={expanded ? classes.expandOpen : ''}
                        aria-expanded={expanded}
                        aria-label="Show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow className={!expanded ? classes.rowEmpty : ''}>
          <TableCell colSpan='100%' className={!expanded ? classes.rowEmpty : ''}>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <pre>
                <code>
                  {item.code}
                </code>
              </pre>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  handleRowExpand = () => {
    this.setState({ expanded: !this.state.expanded });
  };
}

export default withStyles(styles)(RowItem);
