import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import { Typography, withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Toolbar from '@material-ui/core/Toolbar';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import {
  addUsers,
  deleteUsers,
  exportToExcel,
  getUsers,
  selectUserItem,
  updateUserName
} from '../../../store/actions/userActions';
import Button from '@material-ui/core/Button';
import { toastr } from 'react-redux-toastr';
import TextField from '@material-ui/core/TextField';
import AdminMenu from '../../../components/admin/ProgrammingAdminMenu/ProgrammingAdminMenu';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import { fade } from '@material-ui/core/styles';
import mova from 'mova';
import Preloader from '../../../components/common/Preloader/Preloader';
import ChangeNameModal from '../../../components/admin/ChangeNameModal/ChangeNameModal';
import { formatDateTime, testDuration } from '../../../util/dateTime';
import { testScoreLabel } from '../../../util/util';
import AddUserModal from '../../../components/admin/AddUserModal/AddUserModal';

const t = mova.ns('admin');

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let TableToolbar = props => {
  const { numSelected, classes, deleteSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {
          numSelected > 0 &&
          <Typography color='inherit' variant='subtitle1'>
            {numSelected} {t('selected')}
          </Typography>
        }
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {
          numSelected > 0 &&
          <Tooltip title={t('delete')}>
            <IconButton aria-label='Delete' onClick={deleteSelected}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        }
      </div>
    </Toolbar>
  );
};

TableToolbar = withStyles(toolbarStyles)(TableToolbar);

const styles = theme => ({
  root: {
    padding: theme.spacing(3)
  },
  table: {
    minWidth: 1020
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  checkbox: {
    width: '100px'
  },
  button: {
    margin: theme.spacing(1)
  },
  copyButton: {
    marginLeft: theme.spacing(2)
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
    },
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center'
  }
});

const rows = (t) => [
  { id: 'checkbox', label: '' },
  { id: 'name', label: t('applicantEmail') },
  { id: 'password', label: t('password') },
  { id: 'started', label: t('startedTest') },
  { id: 'finished', label: t('finishedTest') },
  { id: 'start', label: t('startDate') },
  { id: 'duration', label: t('testDuration') },
  { id: 'result', label: t('testResult') },
  { id: 'actions', label: t('actions') }
];

class Users extends Component {
  state = {
    addingUsers: false,
    userToUpdate: null,
    search: ''
  };

  componentDidMount() {
    this.props.getUsers();
  }

  render() {
    const { users, page, rowsPerPage, loading, classes, selected, selectItem, totalItems, exportToExcel } = this.props;
    const { addingUsers, userToUpdate, search } = this.state;

    let detailedView;

    if (loading) {
      detailedView = <Preloader />;
    } else if (users.length === 0) {
      detailedView = <Typography>{t('noUsers')}</Typography>;
    } else {
      detailedView = (
        <Fragment>
          <TableToolbar
            numSelected={selected.length}
            deleteSelected={this.deleteUsers}
          />
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby='tableTitle'>
              <TableHead>
                <TableRow>
                  {rows(t).map(row => (
                    <TableCell
                      key={row.id}
                      align='left'
                      padding='none'
                    >
                      {row.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map(user => {
                  return (
                    <TableRow
                      hover
                      key={user.id}
                      className={classes.row}
                    >
                      <TableCell padding='checkbox' className={classes.checkbox}>
                        <Checkbox checked={selected.includes(user.id)} onClick={(e) => selectItem(e, user.id)} />
                      </TableCell>
                      <TableCell component='th' scope='row' padding='none'>
                        {user.email}
                        <IconButton aria-label="edit" size="small" onClick={() => this.openChangeUsernameModal(user)}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell scope='row' padding='none'>
                        <TextField
                          value={user.uiPassword}
                          margin="none"
                          inputRef={(input) => this[`userPass${user.id}`] = input}
                        />
                        <Button
                          onClick={() => this.copyPassword(user)}
                          className={classes.copyButton}
                          variant='contained'
                          size='small'
                          color='primary'
                        >
                          {t('copy')}
                        </Button>
                      </TableCell>
                      <TableCell align='left' padding='none'>
                        <Checkbox checked={!!(user.test && user.test.start)} disabled={true} />
                      </TableCell>
                      <TableCell align='left' padding='none'>
                        <Checkbox checked={!!(user.test && user.test.finish)} disabled={true} />
                      </TableCell>
                      <TableCell align='left' padding='none'>{formatDateTime(user.test ? user.test.start : undefined)}</TableCell>
                      <TableCell align='left' padding='none'>{testDuration(user.test)}</TableCell>
                      <TableCell align='left' padding='none'>{testScoreLabel(user.test)}</TableCell>
                      <TableCell scope='row' padding='none'>
                        {
                          user.test &&
                          <Button
                            onClick={() => this.viewTest(user.test)}
                            variant='contained'
                            size='small'
                            color='primary'
                          >
                            {t('viewTest')}
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
            component='div'
            count={totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={(event, newPage) => this.handleChangePagination(newPage, rowsPerPage)}
            onChangeRowsPerPage={(event) => this.handleChangePagination(0, event.target.value)}
          />
        </Fragment>
      );
    }

    return (
      <>
        <Paper className={classes.root}>
          <AddUserModal open={!!addingUsers} handleClose={this.closeAddUserModal} addUsers={this.confirmAddUsers} />
          <ChangeNameModal open={!!userToUpdate} handleClose={this.closeChangeUsernameModal} user={userToUpdate} confirm={this.confirmChangeName} />
          <AdminMenu />
          <div className={classes.flexRow}>
            <Button
              variant='contained'
              color='primary'
              onClick={this.openAddUserModal}
              className={classes.button}
            >
              {t('addUsers')}
            </Button>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder={t('searchPlaceholder')}
                value={search}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                onChange={e => this.userSearched(e)}
                inputRef={(input) => this.search = input}
              />
              <Button
                variant='contained'
                color='primary'
                className={classes.button}
                onClick={this.confirmSearchUser}
              >
                {t('search')}
              </Button>
              {
                this.search && this.search.value &&
                <Button
                  className={classes.button}
                  variant='contained'
                  color='secondary'
                  onClick={this.clearSearchUser}
                >
                  {t('clear')}
                </Button>
              }
              <Button
                variant='outlined'
                color='primary'
                className={classes.button}
                onClick={exportToExcel}
              >
                {t('exportToExcel')}
              </Button>
          </div>
          </div>
          {detailedView}
        </Paper>
      </>
    );
  }

  viewTest = (test) => {
    if (test && test.id) {
      this.props.history.push(`/programming/tests/${test.id}`);
    }
  };

  handleChangePagination = (page, rowsPerPage) => {
    const { search } = this.state;

    this.props.getUsers(page, rowsPerPage, search);
  };

  copyPassword = (user) => {
    if (this[`userPass${user.id}`]) {
      const passInput = this[`userPass${user.id}`];
      passInput.select();
      document.execCommand('copy');
      toastr.success(t('passwordCopied'));
    }
  };

  openAddUserModal = () => {
    this.setState({ addingUsers: true });
  };

  closeAddUserModal = () => {
    this.setState({ addingUsers: false });
  };

  openChangeUsernameModal = (user) => {
    this.setState({ userToUpdate: user });
  };

  closeChangeUsernameModal = () => {
    this.setState({ userToUpdate: null });
  };

  confirmAddUsers = (users, password) => {
    const { page, rowsPerPage, addUsers } = this.props;

    addUsers(users, password, page, rowsPerPage);
  };

  confirmChangeName = (user, newName) => {
    const { page, rowsPerPage, updateUserName } = this.props;

    updateUserName(user, newName, page, rowsPerPage);
  };

  userSearched = (e) => {
    this.setState({ search: e.target.value });
  };

  confirmSearchUser = () => {
    const { page, rowsPerPage, getUsers } = this.props;
    const { search } = this.state;

    getUsers(page, rowsPerPage, search);
  };

  clearSearchUser = () => {
    this.setState({ search: '' }, this.confirmSearchUser);
  };

  deleteUsers = () => {
    const { selected, page, rowsPerPage, deleteSelected } = this.props;

    deleteSelected(selected, page, rowsPerPage);
    this.setState({ search: '' });
  };
}

const mapStateToProps = ({ users }) => {
  return {
    users: users.content,
    page: users.page,
    rowsPerPage: users.size,
    totalItems: users.totalElements,
    loading: users.fetching,
    selected: users.selected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (page, rowsPerPage, email) => dispatch(getUsers(page, rowsPerPage, email)),
    selectItem: (e, id) => dispatch(selectUserItem(e, id)),
    deleteSelected: (selected, page, rowsPerPage) => dispatch(deleteUsers(selected, page, rowsPerPage)),
    addUsers: (users, password, page, rowsPerPage) => dispatch(addUsers(users, password, page, rowsPerPage)),
    updateUserName: (user, newName, page, rowsPerPage) => dispatch(updateUserName(user, newName, page, rowsPerPage)),
    exportToExcel: () => dispatch(exportToExcel())
  };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Users));
