import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Preloader from '../../../components/common/Preloader/Preloader';
import ThankYou from '../../user/ThankYou/ThankYou';
import Test from '../../user/Test/Test';
import StartTest from '../../user/StartTest/StartTest';
import { getAllTasks } from '../../../store/actions/taskActions';
import { ROLE_ADMIN, ROLE_USER } from '../../../util/constants';
import { Redirect } from 'react-router-dom';

function ViewSelector(props) {
  const { user, getTasks, loading, tasks } = props;
  useEffect(() => {
    if (!tasks.length) {
      getTasks();
    }
  });

  if (loading) {
    return <Preloader />;
  }

  if (user && user.role === ROLE_USER) {
    if (user.test) {
      const test = user.test;

      if (test.finish || test.timeLeft === 0) {
        return <ThankYou />;
      } else {
        return <Test />;
      }
    } else {
      return <StartTest />;
    }
  } else if (user && user.role === ROLE_ADMIN) {
    return <Redirect to='/programming' />;
  }

  return null;
}

const mapStateToProps = ({ user, tasks }) => {
  return {
    user,
    loading: tasks.loading,
    tasks: tasks.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTasks: () => dispatch(getAllTasks())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSelector);
