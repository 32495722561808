import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const oneMinuteMillis = 60 * 1000;

const formatMillis = (millis) => {
  if (millis <= 0) {
    return '00:00';
  }

  let minutes = Math.floor(millis / oneMinuteMillis);
  const seconds = Math.round((millis - minutes * oneMinuteMillis) / 1000);
  let hours = 0;

  if (minutes >= 60) {
    hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
  }

  return `${hours ? `${hours}:` : ''}${(`0${minutes}`).slice(-2)}:${(`0${seconds}`).slice(-2)}`;
};

function Timer(props) {
  const { millis, onFinish, className } = props;

  const [timePassed, setTimePassed] = useState(0);

  useEffect(() => {
    const timerId = setInterval(() => {
      setTimePassed(oldValue => {
        if (oldValue >= millis) {
          onFinish && onFinish();
        }

        return oldValue + 1000;
      });
    }, 1000);

    return () => {
      clearInterval(timerId);
      setTimePassed(0);
    }
  }, [millis, onFinish]);

  return (
    <Typography variant='h4' className={className}>{formatMillis(millis - timePassed)}</Typography>
  );
}

Timer.propTypes = {
  millis: PropTypes.number.isRequired,
  onFinish: PropTypes.func
};

Timer.defaultProps = {
  onFinish: undefined
};

export default Timer;
