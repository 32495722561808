import React, { memo } from 'react';
import { useField } from 'formik';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import AnswerOptionImage from '../AnswerOptionImage/AnswerOptionImage';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
  },
  imagesRoot: {
    flexDirection: 'row'
  },
  optionRoot: {
    maxWidth: '297px',
    marginBottom: theme.spacing(2),
    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    }
  },
  hidden: {
    display: 'none'
  },
  margin0: {
    marginLeft: 0,
  },
  label: {
    paddingTop: theme.spacing(2)
  },
  error: {
    marginBottom: theme.spacing(2)
  }
}));

const FormikRadioGroup = ({ name, options, ...rest }) => {
  const [field, meta, helpers] = useField(name);
  const classes = useStyles();
  const hasImages = options.some(o => !!o.imageUrl);

  const imageClicked = (id) => {
    helpers.setValue(JSON.stringify([id]));
  };

  const optionItems = options.map(o => (
    <FormControl key={o.id} className={clsx({ [classes.optionRoot]: hasImages })}>
      {!!o.imageUrl && <AnswerOptionImage option={o} onClick={imageClicked} value={field.value} />}
      <FormControlLabel
        value={JSON.stringify([o.id])}
        label={o.text}
        control={<Radio className={clsx({ [classes.hidden]: hasImages })} />}
        className={clsx({ [classes.margin0]: hasImages })}
      />
    </FormControl>
  ));

  return (
    <FormControl component='fieldset'>
      <RadioGroup {...field} {...rest} className={clsx(classes.root, { [classes.imagesRoot]: hasImages })}>
        {optionItems}
      </RadioGroup>
      {
        meta.touched && !!meta.error &&
        <Typography variant='body1' color='secondary' className={classes.error}>{meta.error}</Typography>
      }
    </FormControl>
  );
};

export default memo(FormikRadioGroup);

