import React, { Fragment, useEffect } from 'react';
import 'codemirror/keymap/sublime';
import { connect } from 'react-redux';
import initData from './store/actions/InitialDataFetch';
import Preloader from './components/common/Preloader/Preloader';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppRoutes from './router/AppRoutes';
import Header from './components/common/Header/Header';
import i18n from './i18n';

function App(props) {
  const { loading, initData, lang } = props;

  useEffect(initData, []);

  useEffect(() => {
    if (lang) {
      i18n.init(lang);
    }
  }, [lang]);

  if (loading) {
    return <Preloader />;
  }

  return (
    <Fragment>
      <CssBaseline />
      <Header />
      <AppRoutes />
    </Fragment>
  );
}

const mapStateToProps = ({user, initialLoading, config}) => {
  return {
    user,
    loading: initialLoading,
    lang: config.lang
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initData: () => dispatch(initData())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
