import api from '../../services/api';
import Actions from './actionTypes';
import { toastr } from 'react-redux-toastr';
import mova from 'mova';
import { getCurrentUser } from './userActions';

const t = mova.ns('base');

export const getAllTasks = () => dispatch => {
  dispatch({ type: Actions.TASKS_INFO_LOADNIG, payload: true });
  return api.get('/api/tasks')
    .then(tasks => {
      dispatch({ type: Actions.TASKS_INFO, payload: tasks });
      dispatch({ type: Actions.TASKS_INFO_LOADNIG, payload: false });
    });
};

export const updateActiveTaskCode = (code) => dispatch => {
  dispatch({ type: Actions.UPDATE_ACTIVE_TASK_CODE, payload: code });
};

export const resetCode = () => dispatch => {
  dispatch({ type: Actions.RESET_CODE });
  dispatch({ type: Actions.INCREMENT_CODE_VERSION });
};

export const submitTask = (task) => dispatch => {
  const body = {
    className: `${task.name}.java`,
    code: task.code || task.initialCode
  };

  return api.post('/api/tasks/check', body)
    .then(response => {
      dispatch({ type: Actions.SET_SERVER_RESPONSE, payload: response });

      if (response.testsCompleted === response.testsTotal) {
        dispatch({ type: Actions.TASK_COMPLETED, payload: task.ordinalNumber });
        toastr.success(t('success'));
      }
    })
    .catch(() => {
      dispatch(getCurrentUser())
    });
};

export const changeActiveItem = (task) => dispatch => {
  dispatch({ type: Actions.SET_ACTIVE_TASK, payload: task.ordinalNumber - 1 });
};
