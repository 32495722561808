import React, { memo } from 'react';
import mova from 'mova';
import { makeStyles } from '@material-ui/core/styles';
import FormikRadioGroup from '../../../../../components/common/FormikRadioGroup/FormikRadioGroup';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import FormikCheckboxGroup from '../../../../../components/common/FormikCheckboxGroup/FormikCheckboxGroup';

const t = mova.ns('choiceTest');
const formT = mova.ns('admin.forms');

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(4)
  },
  text: {
    fontWeight: 'normal'
  },
  img: {
    paddingLeft: theme.spacing(4)
  }
}));

const schema = yup.object().shape({
  answer: yup.string().required(() => formT('validation.required')),
});

const QuestionAnswer = ({ question, answerQuestion, resultId }) => {
  const classes = useStyles();

  const answerUiElements = question.multipleAnswers ?
    <FormikCheckboxGroup name='answer' options={question.options} questionId={question.id} /> :
    <FormikRadioGroup name='answer' options={question.options} />;

  return (
    <div className={classes.root}>
      <Typography variant='h5' className={classes.heading}>{t('selectAnswer')}:</Typography>
      <div className={classes.answers}>
        <Formik
          initialValues={{ answer: '' }}
          validationSchema={schema}
          onSubmit={(values) => answerQuestion(resultId, values.answer)}
        >
          {(props) => (
            <Form>
              {answerUiElements}
              <div>
                <Button type='submit' variant='contained' color='primary' disabled={props.isSubmitting}>
                  {t('submitAnswer')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default memo(QuestionAnswer);
