export default {
  name: "ua",
  base: {
    signin: "Логін",
    logout: "Вийти",
    email: "Е-мейл",
    password: "Пароль",
    cookies: "Необхідно включити cookie для використання цього сервісу",
    success: "Завдання виконано успішно!",
  },
  tasks: {
    1: "Моя перша програма",
    2: "Простий вивід тексту",
    3: "Арифметика",
    4: "Программа з умовою",
    5: "Вчимося рахувати",
    6: "Щасливі числа",
    7: "Тепер йдемо навпаки",
    8: "Більше математики",
    9: "Вчать у школі",
    10: "Калькулятор",
  },
  test: {
    instructions: "Інструкція тесту",
    start: "Розпочати тест",
    readInstructions: "Прочитати інструкцію",
    description:
      "У Вас буде півтори години на виконання тесту. Після того як Ви розпочнете, у Вас не буде можливості призупинити виконання тесту та повернутись на цю сторінку. Будь ласка, спочатку уважно прочитайте інструкцію тесту.",
    confirmFinish1:
      "Ви впевнені, що хочете завершити тест? Ви не зможете продовжити виконання інших завдань.",
    confirmFinish2:
      "Ви будете перенаправлені на іншу сторінку і не зможете повернутись до цього вікна.",
    confirmFinish3:
      "Натискаючи кнопку Завершити тест, Ви підтверджуєте, що виконали максимум завдань та погоджуєтесь зі своїм результатом.",
    finishing: "Завершення тесту",
    cancel: "Відміна",
    finish: "Завершити",
    submit: "Відправити",
    reset: "Початковий код",
    finishButton: "Завершити тест",
    responseFromServer: "Відповідь сервера",
    yourResult: "Результат Вашого коду",
    expectedResult: "Очікуваний результат",
  },
  admin: {
    tries: "Спроби",
    tests: "Тести",
    score: "Результат",
    task: "Завдання",
    submissionTime: "Час виконання",
    completed: "Тестів виконано",
    total: "Усього тестів",
    selected: "обрано",
    applicantEmail: "Е-мейл кандидата",
    applicant: "Кандидат",
    question: "Питання",
    image: "Зображення",
    noImage: "Немає зображення",
    answers: "Відповіді",
    answer: "Відповідь",
    answerType: "Тип відповіді",
    correct: "Правильний",
    incorrect: "Неправильний",
    startDate: "Дата початку",
    finishDate: "Дата завершення",
    startedTest: "Тест розпочато",
    finishedTest: "Тест завершено",
    testDuration: "Тривалість тесту",
    testResult: "Результат тесту",
    testsCompleted: "Тестів виконано",
    testsTotal: "Усього тестів",
    result: "Загальний результат",
    noTests: "Тестів не знайдено",
    searchPlaceholder: "Пошук...",
    search: "Пошук",
    clear: "Очистити",
    delete: "Видалити",
    exportToExcel: "Вивантажити в Excel",
    actions: "Дії",
    addUsers: "Додати користувачів",
    addQuestion: "Додати опитування",
    addOption: "Додати варіант відповіді",
    add: "Додати",
    edit: "Редагувати",
    noUsers: "Користувачів не знайдено",
    noQuestions: "Питань не знайдено",
    emailsPlaceholder: "Е-мейли користувачів",
    copy: "Копіювати",
    viewTest: "Відкрити тест",
    password: "Пароль",
    passwordCopied: "Пароль скопійовано до буферу обміну",
    passwordPlaceholder: "Пароль (опційно)",
    menu: {
      userManagement: "Користувачі",
      availableTests: "Тести",
      userTest: "Тест кандидата",
      choiceTests: "Тести",
      choiceTestQuestions: "Питання",
      choiceTestUsers: "Користувачі",
      choiceTestDetails: "Деталі тесту",
      choiceTestCompleted: "Виконані тести",
    },
    editUserEmail: "Зміна імені користувача",
    changeName: "Змінити ім’я",
    addChoiceTest: "Додати тест",
    cancel: "Відміна",
    newUserName: "Нове ім’я",
    noChoiceTests: "Тестів не знайдено",
    forms: {
      addChoiceTest: "Додати тест",
      editChoiceTest: "Редагувати тест",
      addQuestion: "Додати питання",
      editQuestion: "Редагувати питання",
      addOption: "Додати варіант відповіді",
      editOption: "Редагувати варіант відповіді",
      namePlaceholder: "Назва",
      questionPlaceholder: "Питання",
      optionPlaceholder: "Текст відповіді",
      noOfQuestionsPlaceholder: "Кількість питань",
      minutesPlaceholder: "Хвилин на виконання",
      dateStartPlaceholder: "Дата та час початку",
      dateFinishPlaceholder: "Дата та час завершення",
      imagePlaceholder: "Зображення",
      isCorrectPlaceholder: "Відповідь вірна",
      yes: "Так",
      no: "Ні",
      add: "Додати",
      edit: "Редагувати",
      validation: {
        required: "Не можна відправити порожню відповідь",
        min: "Не можна вказати менше <=value>",
      },
    },
  },
  thankYou: {
    text: "Дякуємо за проходження тестування. Зверніться, будь ласка, до Вашого менеджера для отримання результату тесту та його інтерпретації.",
  },
  page404: {
    text: "Вибачте, запитувану сторінку не знайдено",
    toMain: "Повернутися на головну сторінку",
  },
  header: {
    programming: "Програмування",
    other: "Інші тести",
  },
  testCard: {
    minutes: "хвилин",
    questions: "питань",
    tasks: "задач",
    totalQuestions: "питань усього",
    totalUsers: "користувачів додано",
    start: "Розпочати тест",
    continue: "Продовжити тест",
    editTest: "Редагувати",
    deleteTest: "Видалити",
    questionsLink: "Питання",
    users: "Користувачі",
    deleteModalTitle: "Ви впевнені, що хочете видалити даний тест?",
    deleteModalText: "Усі його питання, користувачі та результати будуть видалені з системи",
    frontEnd: "Тест на курс FrontEnd",
    fullStack: "Тест на курс Full Stack",
    dateStart: "Дата початку",
    dateFinish: "Дата завершення",
  },
  confirmDeleteModal: {
    confirm: "Підтвердити",
    cancel: "Відміна",
  },
  myTests: {
    list: "Перелік доступних тестів",
  },
  choiceTest: {
    question: "Питання",
    selectAnswer: "Оберіть правильну відповідь",
    of: "з",
    finishButton: "Завершити тест",
    submitAnswer: "Відправити",
    severalAnswers: "У даному питанні можна обрати кілька варіантів відповіді",
  },
};
