import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ListAltIcon from '@material-ui/icons/ListAlt';
import HelpIcon from '@material-ui/icons/HelpOutline';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AssignmentIndexIcon from '@material-ui/icons/AssignmentInd';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withRouter } from 'react-router-dom';
import mova from 'mova';

const t = mova.ns('admin.menu');

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2)
  },
}));

function ChoiceAdminMenu(props) {
  const { match, history } = props;

  const classes = useStyles();
  const { testId, solutionId } = match.params;

  function handleChange(event, newValue) {
    history.push(newValue);
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={match.url}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs"
        >
          <Tab value='/choice' label={t('choiceTests')} icon={<ListAltIcon />} {...a11yProps(0)} />
          <Tab
            disabled={!testId}
            value={`/choice/${testId}/questions`}
            label={t('choiceTestQuestions')}
            icon={<HelpIcon />}
            {...a11yProps(1)}
          />
          <Tab
            disabled={!testId}
            value={`/choice/${testId}/users`}
            label={t('choiceTestUsers')}
            icon={<PersonAddIcon />}
            {...a11yProps(2)}
          />
          <Tab
            disabled={!testId}
            value={`/choice/${testId}/results`}
            label={t('choiceTestCompleted')}
            icon={<EqualizerIcon />}
            {...a11yProps(3)}
          />
          <Tab
            disabled
            value={`/choice-tests/${testId}/${solutionId}/details`}
            label={t('choiceTestDetails')}
            icon={<AssignmentIndexIcon />}
            {...a11yProps(4)}
          />
        </Tabs>
      </AppBar>
    </div>
  );
}

export default withRouter(ChoiceAdminMenu);
