import React, { memo } from 'react';
import { useField } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
  },
  error: {
    color: theme.palette.secondary
  },
}));

const FormikCheckbox = ({ name, label, ...rest }) => {
  const [field, meta] = useField(name);
  const classes = useStyles();

  return (
    <div>
      <FormControlLabel control={<Checkbox {...field} {...rest} color="primary" />} label={label} />
      {meta.touched && !!meta.error && <div className={classes.error}>{meta.error}</div>}
    </div>
  );

};

export default memo(FormikCheckbox);
