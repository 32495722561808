export default {
  name: 'en',
  base: {
    signin: 'Sign In',
    logout: 'Log out',
    email: 'Email Address',
    password: 'Password',
    cookies: 'You need to enable cookies to use this service',
    success: 'Task completed successfully!'
  },
  tasks: {
    1: 'My first program',
    2: 'Text output',
    3: 'Basic arithmetics',
    4: 'Conditions',
    5: 'Counting',
    6: 'Happy numbers',
    7: 'Reverse counting',
    8: 'More math',
    9: 'Multiplication table',
    10: 'Calculator'
  },
  test: {
    instructions: 'Test instructions',
    start: 'Start test',
    readInstructions: 'Read test instructions',
    description: 'You will have 1 hour and 30 minutes to complete the test. Once you start, you won\'t be able to pause the completion or return to this page. Please read test instructions carefully first.',
    confirmFinish1: 'Are you sure you want to timeToFinish your test? You won\'t be able to submit any more answers.',
    confirmFinish2: 'You will be redirected to a different page and won\'t be able to return to this view anymore.',
    confirmFinish3: 'By clicking Finish button, you confirm that you have done your best and satisfied with your results.',
    finishing: 'Finishing test',
    cancel: 'Cancel',
    finish: 'Finish',
    submit: 'Submit',
    reset: 'Reset to initial',
    finishButton: 'Finish test',
    responseFromServer: 'Response from server',
    yourResult: 'Result of your code',
    expectedResult: 'Expected result'
  },
  admin: {
    tries: 'Tries',
    tests: 'Tests',
    score: 'Score',
    task: 'Task',
    submissionTime: 'Submission Time',
    completed: 'Tests Completed',
    total: 'Tests Total',
    selected: 'selected',
    applicantEmail: 'Applicant Email',
    startDate: 'Start Date',
    finishDate: 'Finish Date',
    startedTest: 'Started test',
    finishedTest: 'Finished test',
    testDuration: 'Test Duration',
    testResult: 'Test result',
    testsCompleted: 'Tests completed',
    testsTotal: 'Tests total',
    result: 'Total Result',
    noTests: 'No tests available',
    searchPlaceholder: 'Search...',
    search: 'Search',
    clear: 'Clear',
    delete: 'Delete',
    exportToExcel: 'Export to Excel',
    actions: 'Actions',
    addUsers: 'Add users',
    addQuestion: 'Add question',
    addOption: 'Add option',
    add: 'Add',
    edit: 'Edit',
    noUsers: 'No users available',
    noQuestions: 'No questions available',
    emailsPlaceholder: 'Use emails',
    copy: 'Copy',
    viewTest: 'View test',
    password: 'Password',
    passwordCopied: 'Password copied to clipboard',
    passwordPlaceholder: 'Password (optional)',
    applicant: 'Applicant',
    question: 'Question',
    image: 'Image',
    noImage: 'No image',
    answers: 'Answers',
    answer: 'Answer',
    answerType: 'Type',
    correct: 'Correct',
    incorrect: 'Incorrect',
    menu: {
      userManagement: 'User Management',
      availableTests: 'Tests',
      userTest: 'User Test',
      choiceTests: 'Tests',
      choiceTestQuestions: 'Questions',
      choiceTestUsers: 'Users',
      choiceTestDetails: 'Details',
      choiceTestCompleted: 'Completed Tests'
    },
    editUserEmail: 'Edit user email',
    changeName: 'Change name',
    addChoiceTest: 'Add test',
    cancel: 'Cancel',
    newUserName: 'New name',
    noChoiceTests: 'No tests available',
    forms: {
      addChoiceTest: 'Add test',
      editChoiceTest: 'Edit test',
      addQuestion: 'Add question',
      editQuestion: 'Edit question',
      addOption: 'Add option',
      editOption: 'Edit option',
      namePlaceholder: 'Name',
      questionPlaceholder: 'Question',
      optionPlaceholder: 'Option text',
      noOfQuestionsPlaceholder: 'Number of questions',
      minutesPlaceholder: 'Minutes to complete',
      dateStartPlaceholder: 'Date/time of start',
      dateFinishPlaceholder: 'Date/time of finish',
      imagePlaceholder: 'ChoiceTestCard',
      isCorrectPlaceholder: 'Answer is correct',
      yes: 'Yes',
      no: 'No',
      add: 'Add',
      edit: 'Edit',
      validation: {
        required: 'You need to choose an option to submit',
        min: 'The minimum value is <=value>'
      }
    },
  },
  thankYou: {
    text: 'Thank you for completing the test! We will contact you shortly regarding your results.'
  },
  page404: {
    text: 'Sorry but we could not find the page you are looking for',
    toMain: 'Back to main page'
  },
  header: {
    programming: 'Programming',
    other: 'Other tests'
  },
  testCard: {
    minutes: 'minutes',
    questions: 'questions',
    tasks: 'tasks',
    totalQuestions: 'questions total',
    totalUsers: 'users added',
    start: 'Start test',
    continue: 'Continue test',
    editTest: 'Edit test',
    deleteTest: 'Delete test',
    questionsLink: 'Questions',
    users: 'Users',
    deleteModalTitle: 'Are you sure you want to delete this test?',
    deleteModalText: 'All it\'s questions, users, and results will be deleted',
    frontEnd: 'FrontEnd test',
    fullStack: 'Programming test',
    dateStart: 'Date of start',
    dateFinish: 'Date of finish'
  },
  confirmDeleteModal: {
    confirm: 'Confirm',
    cancel: 'Cancel'
  },
  myTests: {
    list: 'List of available tests'
  },
  choiceTest: {
    question: 'Question',
    selectAnswer: 'Select correct answer',
    of: 'of',
    finishButton: 'Finish test',
    submitAnswer: 'Submit answer',
    severalAnswers: 'This question allows selecting several answers'
  }
};
