import api from '../../services/api';
import Actions from './actionTypes';

export const getChoiceTests = () => dispatch => {
  dispatch({ type: Actions.CHOICE_TESTS_FETCHING, payload: true });
  return api.get('/api/choice-tests')
    .then(tests => {
      dispatch({ type: Actions.SAVE_CHOICE_TESTS, payload: tests });
      dispatch({ type: Actions.CHOICE_TESTS_FETCHING, payload: false });
    });
};

export const addEditChoiceTest = (test, imageRef) => dispatch => {
  const body = new FormData();

  Object.keys(test).forEach(key => {
    if (key !== 'imageUrl') {
      if (key.startsWith('date')) {
        if (!!test[key]) {
          body.append(key, new Date(test[key]).getTime())
        }
      } else {
        body.append(key, test[key]);
      }
    }
  });

  if (!!test.imageUrl) {
    body.append('imageUrl', imageRef.current.files[0]);
  }

  if (test.id) {
    return api.put(`/api/choice-tests/${test.id}`, body)
      .then(updatedTest => {
        dispatch({ type: Actions.UPDATE_CHOICE_TEST, payload: updatedTest });
      });
  } else {
    return api.post('/api/choice-tests', body)
      .then(createdTest => {
        dispatch({ type: Actions.NEW_CHOICE_TEST, payload: createdTest });
      });
  }
};

export const deleteChoiceTest = (test) => (dispatch) => {
  return api.deleteApi(`/api/choice-tests/${test.id}`)
    .then(() => {
      dispatch({ type: Actions.CHOICE_TEST_DELETED, payload: test });
    });
};
