import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Image from '../../../../../components/common/Image/Image';

import mova from 'mova';
import { WH_PROPORTION } from '../../../../../util/constants';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const t = mova.ns('choiceTest');

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(4)
  },
  heading: {
    marginBottom: theme.spacing(2)
  },
  question: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  text: {
    fontWeight: 'normal'
  },
  helperText: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    color: '#828282',
    fontSize: '12px'
  },
  helperIcon: {
    marginRight: theme.spacing(1)
  },
  img: {
    paddingLeft: theme.spacing(4)
  }
}));

const QuestionTask = ({ question }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant='h5' className={classes.heading}>{t('question')}:</Typography>
      <div className={classes.question}>
        <div>
          <Typography variant='body1' className={classes.text}>{question.text}</Typography>
          {
            question.multipleAnswers && (
              <Typography variant='body2' className={classes.helperText}>
                <ErrorOutlineIcon className={classes.helperIcon} /> {t('severalAnswers')}
              </Typography>
            )
          }
        </div>
        {
          !!question.imageUrl &&
          <Image width={375} height={375 / WH_PROPORTION} className={classes.img} imageUrl={question.imageUrl} />
        }
      </div>
    </div>
  );
};

export default memo(QuestionTask);
