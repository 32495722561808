import React, { Fragment, useCallback, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import PropTypes from 'prop-types';
import TimeIcon from '@material-ui/icons/AccessTime';
import QuestionIcon from '@material-ui/icons/HelpOutline';
import UserIcon from '@material-ui/icons/AccountCircle';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { addEditChoiceTest, deleteChoiceTest } from '../../../store/actions/choiceTestsActions';
import Image from '../Image/Image';
import mova from 'mova';
import AddEditChoiceTestModal from '../../admin/AddEditChoiceTestModal/AddEditChoiceTestModal';
import IconButton from '@material-ui/core/IconButton';
import ConfirmDeleteModal from '../../admin/ConfirmDeleteModal/ConfirmDeleteModal';
import { useHistory } from 'react-router-dom';
import { WH_PROPORTION } from '../../../util/constants';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import StopIcon from '@material-ui/icons/Stop';
import DoneIcon from '@material-ui/icons/Done';

const t = mova.ns('testCard');

const useStyles = makeStyles((theme) => ({
  root: props => ({
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 345,
    cursor: props.adminView ? 'pointer' : 'unset'
  }),
  flexRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(1)
  },
  rowIcon: {
    marginRight: theme.spacing(1)
  },
  actions: {
    display: 'block'
  },
  actionRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  media: {
    position: 'relative'
  },
  imageFinished: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1
  },
  iconBackground: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    backgroundColor: '#EEC02D',
  }
}));

const ChoiceTestCard = (props) => {
  const { test, result, adminView, deleteTest, confirmEditTest, onClick } = props;

  const [editingTest, setEditingTest] = useState(false);
  const [deletingTest, setDeletingTest] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles(props);
  const history = useHistory();

  const openEditModal = useCallback(() => {
    setEditingTest(true);
  }, []);

  const closeEditModal = useCallback(() => {
    setEditingTest(false);
  }, []);

  const openDeleteModal = useCallback(() => {
    setDeletingTest(true);
  }, []);

  const closeDeleteModal = useCallback(() => {
    setDeletingTest(false);
  }, []);

  const goToQuestions = useCallback(() => {
    history.push(`/choice/${test.id}/questions`);
  }, [history, test]);

  const goToUsers = useCallback(() => {
    history.push(`/choice/${test.id}/users`);
  }, [history, test]);

  const expandActions = useCallback((e) => {
    e.stopPropagation();
    setExpanded(true);
  }, []);

  const confirmDeleteTest = useCallback(() => {
    deleteTest(test);
  }, [deleteTest, test]);

  const startTest = useCallback(() => {
    history.push(`/my/choice/${test.id}`);
  }, [history, test]);

  const testForInfo = result || test;

  return (
    <Fragment>
      <AddEditChoiceTestModal
        open={editingTest}
        handleClose={closeEditModal}
        confirmAction={confirmEditTest}
        test={test}
      />
      <ConfirmDeleteModal
        open={deletingTest}
        handleClose={closeDeleteModal}
        confirmAction={confirmDeleteTest}
        title={t('deleteModalTitle')}
        text={t('deleteModalText')}
      />
      <Card className={classes.root} onClick={onClick}>
        <CardHeader
          title={test.name}
          action={!expanded && adminView && <IconButton onClick={expandActions}><MoreIcon /></IconButton>}
        />
        <CardMedia className={classes.media}>
          <Image imageUrl={test.imageUrl} width={345} height={345 / WH_PROPORTION} />
          {
            result && result.finished && (
              <div className={classes.imageFinished}>
                <div className={classes.iconBackground}><DoneIcon fontSize='large' /></div>
              </div>
            )
          }
        </CardMedia>
        <CardContent>
          <Typography variant='body2' color='textSecondary' component='p' className={classes.flexRow}>
            <TimeIcon className={classes.rowIcon} fontSize='small' /> {testForInfo.minutes} {t('minutes')}
          </Typography>
          <Typography variant='body2' color='textSecondary' component='p' className={classes.flexRow}>
            <QuestionIcon className={classes.rowIcon} fontSize='small' />
            {testForInfo.numberOfQuestions} {t('questions')}
            {adminView && <span>&nbsp;({test.totalQuestions} {t('totalQuestions')})</span>}
          </Typography>
          {
            adminView && (
              <Typography variant='body2' color='textSecondary' component='p' className={classes.flexRow}>
                <UserIcon className={classes.rowIcon} fontSize='small' /> {test.totalUsers} {t('totalUsers')}
              </Typography>
            )
          }
          {
            test.dateStart && (
              <Typography variant='body2' color='textSecondary' component='p' className={classes.flexRow}>
                <PlayCircleFilledWhiteIcon className={classes.rowIcon} fontSize='small' /> {t('dateStart')}&nbsp;
                {new Date(test.dateStart).toLocaleString()}
              </Typography>
            )
          }
          {
            test.dateFinish && (
              <Typography variant='body2' color='textSecondary' component='p' className={classes.flexRow}>
                <StopIcon className={classes.rowIcon} fontSize='small' /> {t('dateFinish')}&nbsp;
                {new Date(test.dateFinish).toLocaleString()}
              </Typography>
            )
          }
        </CardContent>
        <CardActions disableSpacing className={classes.actions} onClick={(e) => e.stopPropagation()}>
          {
            adminView && (
              <Fragment>
                <div className={classes.actionRow}>
                  <Button variant='contained' color='primary' onClick={goToQuestions} startIcon={<QuestionIcon />}>
                    {t('questionsLink')}
                  </Button>
                  <Button variant='contained' color='primary' onClick={goToUsers} startIcon={<UserIcon />}>
                    {t('users')}
                  </Button>
                </div>
                {
                  expanded && (
                    <div className={classes.actionRow}>
                      <Button variant='contained' color='primary' onClick={openEditModal} startIcon={<CreateIcon />}>
                        {t('editTest')}
                      </Button>
                      <Button
                        variant='contained'
                        color='secondary'
                        onClick={() => openDeleteModal()}
                        startIcon={<DeleteIcon />}
                      >
                        {t('deleteTest')}
                      </Button>
                    </div>
                  )
                }
              </Fragment>
            )
          }
          {
            !adminView && (
              <Button
                variant='contained'
                color='primary'
                onClick={startTest}
                fullWidth
                disabled={result && result.finished}
              >
                {result && !result.finished ? t('continue') : t('start')}
              </Button>
            )
          }
        </CardActions>
      </Card>
    </Fragment>
  );
};

ChoiceTestCard.propTypes = {
  test: PropTypes.object.isRequired,
  adminView: PropTypes.bool
};

ChoiceTestCard.defaultProps = {
  adminView: false
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  deleteTest: (test) => dispatch(deleteChoiceTest(test)),
  confirmEditTest: (test, imageRef) => dispatch(addEditChoiceTest(test, imageRef))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChoiceTestCard);
