import dateFormat from 'dateformat';

export const formatDateTime = (date) => {
  if (!date) {
    return '';
  }

  return dateFormat(new Date(date), 'dd mmm yyyy, HH:MM:ss');
};

export const testDuration = (test) => {
  if (!test || !test.finish) {
    return '';
  }

  const duration = test.finish - test.start;

  let seconds = Math.floor((duration / 1000) % 60);
  let minutes = Math.floor((duration / (1000 * 60)) % 60);
  let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = (hours < 10) ? '0' + hours : hours;
  minutes = (minutes < 10) ? '0' + minutes : minutes;
  seconds = (seconds < 10) ? '0' + seconds : seconds;

  return hours + ':' + minutes + ':' + seconds;
};

export const choiceTestDuration = (test) => testDuration({start: test.createdDate, finish: test.finishedAt});
