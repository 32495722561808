import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from '@material-ui/core';
import mova from 'mova';

const t = mova.ns('confirmDeleteModal');

const ConfirmDeleteModal = ({ open, handleClose, confirmAction, title, text }) => {
  const confirm = () => {
    confirmAction();
    handleClose();
  };

  return (
    <Dialog fullWidth onClose={handleClose} aria-labelledby='confirm-delete' open={open}>
      <DialogTitle id='confirm-delete'>{title}</DialogTitle>
      <DialogContent>{text}</DialogContent>
      <DialogActions>
        <Button variant='contained' color='secondary' onClick={confirm}>{t('confirm')}</Button>
        <Button variant='contained' color='primary' onClick={handleClose}>{t('cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteModal;
