import React, { useRef } from 'react';
import { Form, Formik } from 'formik';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import mova from 'mova';
import FormikInput from '../../common/FormikInput/FormikInput';
import ImagePreview from '../ImagePreview/ImagePreview';
import * as yup from 'yup';

const t = mova.ns('admin.forms');

const schema = yup.object().shape({
  text: yup.string().required(() => t('validation.required'))
});

const AddEditQuestionModal = ({ open, handleClose, confirmAction, question }) => {
  const imageRef = useRef(null);

  const confirm = (values) => {
    confirmAction({ ...(question || {}), ...values }, imageRef);
    handleClose();
  };

  return (
    <Dialog fullWidth onClose={handleClose} aria-labelledby='add-edit-question' open={open}>
      <DialogTitle id='add-edit-question'>{t(!!question ? 'editQuestion' : 'addQuestion')}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            text: (question && question.text) || '',
            imageUrl: ''
          }}
          validationSchema={schema}
          onSubmit={confirm}
        >
          {(props) => {
            const handleCloseImg = () => {
              props.setFieldValue('imageUrl', '');
            };

            return (
              <Form>
                <FormikInput
                  variant='outlined'
                  name='text'
                  label={t('questionPlaceholder')}
                  placeholder={t('questionPlaceholder')}
                  fullWidth
                />
                <FormikInput
                  inputProps={{ accept: 'image/*' }}
                  variant='outlined'
                  name='imageUrl'
                  type='file'
                  fullWidth
                  inputRef={imageRef}
                />
                {!!props.values.imageUrl &&
                <ImagePreview inputRef={imageRef} close={handleCloseImg} alt='Question image' />}

                <Button type='submit' variant='contained' color='secondary'>{t(!!question ? 'edit' : 'add')}</Button>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditQuestionModal;
