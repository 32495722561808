import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import RowItem from './RowItem';
import GroupedResultsTable from './GroupedResultsTable';
import AdminMenu from '../../../components/admin/ProgrammingAdminMenu/ProgrammingAdminMenu';
import mova from 'mova';
import Preloader from '../../../components/common/Preloader/Preloader';
import { getTest } from '../../../store/actions/testActions';
import { getAllTasks } from '../../../store/actions/taskActions';
import { testScoreLabel } from '../../../util/util';
import { testDuration } from '../../../util/dateTime';

const t = mova.ns('admin');

const styles = theme => ({
  root: {
    padding: theme.spacing(3)
  },
  textData: {
    fontWeight: 'bold'
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    margin: '50px 0',
    overflowX: 'auto',
  },
});

const rows = (t) => [
  { id: 'task', label: t('task') },
  { id: 'submission', label: t('submissionTime') },
  { id: 'completed', label: t('testsCompleted') },
  { id: 'total', label: t('testsTotal') },
  { id: 'score', label: t('score') },
  { id: 'expand', label: '' }
];

class TestResults extends Component {
  componentDidMount() {
    const { getTest, tasks, getTasks, match } = this.props;

    getTest(match.params.testId);

    if (!tasks.length) {
      getTasks()
    }
  }

  render() {
    const { test, loading, classes } = this.props;

    if (!test || loading) {
      return <Preloader />;
    }

    return (
      <Paper className={classes.root}>
        <AdminMenu />
        <Typography component='h2'>
          {t('applicant')}: <span className={classes.textData}>{test.user.email}</span>
        </Typography>

        <Typography component='h2'>
          {t('result')}: <span className={classes.textData}>{testScoreLabel(test)}</span>
        </Typography>

        <Typography component='h2'>
          {t('testDuration')}: <span className={classes.textData}>{testDuration(test)}</span>
        </Typography>

        <div className={classes.tableWrapper}>
          <GroupedResultsTable test={test} />
        </div>

        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby='tableTitle'>
            <TableHead>
              <TableRow>
                {rows(t).map(row => (
                  <TableCell
                    key={row.id}
                    align='left'
                    padding='none'
                  >
                    {row.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {test.solutions.map(item => <RowItem item={item} key={item.id} />)}
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = ({ tests, tasks }, ownProps) => {
  const testId = parseInt(ownProps.match.params.testId);
  const currentTest = tests.currentTest && tests.currentTest.id === testId ? tests.currentTest : null

  return {
    test: currentTest,
    loading: tasks.loading,
    tasks: tasks.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTest: (testId) => dispatch(getTest(testId)),
    getTasks: () => dispatch(getAllTasks())
  };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TestResults));
