import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import { Link, withRouter } from 'react-router-dom';
import { logoutUser } from '../../../store/actions/userActions';
import mova from 'mova';

const t = mova.ns('base');
const headerT = mova.ns('header');

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  menuLink: {
    display: 'inline-block',
    margin: '0 12px',
    fontSize: 18,
    fontWeight: 500,
    color: '#fff',
    textDecoration: 'none',
    textTransform: 'uppercase'
  },
  logo: {
    height: '40px'
  }
}));

function Header(props) {
  const { user, logOut } = props;
  const classes = useStyles();
  const [anchorEl, senAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const userIsAdmin = user && user.role === 'ADMIN';

  const handleMenu = event => {
    senAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    senAnchorEl(null);
  };

  const goHome = () => {
    props.history.push('/');
  };

  return (
    <div className={classes.root}>
      <AppBar position='static'>
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            onClick={goHome}
            color='inherit'
            aria-label='Menu'>
            <img className={classes.logo} src='/img/dan-logo.png' alt='DAN.IT' />
          </IconButton>
          {
            userIsAdmin && (
              <Fragment>
                <Link
                  to='/programming'
                  className={classes.menuLink}
                  aria-label='Menu'
                >
                  {headerT('programming')}
                </Link>
                <Link
                  className={classes.menuLink}
                  to='/choice'
                  aria-label='Menu'
                >
                  {headerT('other')}
                </Link>
              </Fragment>
            )
          }
          <div className={classes.grow} />
          <div>
            {
              user &&
              <IconButton
                aria-owns={open ? 'menu-appbar' : undefined}
                aria-haspopup='true'
                onClick={handleMenu}
                color='inherit'
              >
                <AccountCircle />
              </IconButton>
            }
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={logOut}>{t('logout')}</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

const mapStateToProps = ({ user }) => {
  return {
    user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(logoutUser())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
