import React, { Fragment, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import EditIcon from '@material-ui/icons/Edit';
import { addEditQuestion, selectChoiceTestQuestion } from '../../../../store/actions/choiceTestActions';
import AddEditQuestionModal from '../../../../components/admin/AddEditQuestionModal/AddEditQuestionModal';
import Checkbox from '@material-ui/core/Checkbox';
import Image from '../../../../components/common/Image/Image';
import QuestionOptions from './QuestionOptions';
import { makeStyles } from '@material-ui/core/styles';
import mova from 'mova';
import clsx from 'clsx';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { WH_PROPORTION } from '../../../../util/constants';

const t = mova.ns('admin');

const useStyles = makeStyles(theme => ({
  checkbox: {
    width: '100px'
  },
  ready: {
    backgroundColor: fade('rgb(0, 255, 0)', 0.2)
  },
  notReady: {
    backgroundColor: fade(theme.palette.secondary.main, 0.1)
  }
}));

const questionReady = (q) => q.options.some(o => o.correct) && q.options.some(o => !o.correct);

const QuestionTableRow = ({ question, editQuestion, testId, page, rowsPerPage, selected, selectItem }) => {
  const [open, setOpen] = useState(false);
  const [updatingQuestion, setUpdatingQuestion] = useState(false);
  const classes = useStyles();

  const toggleRowOpen = useCallback(() => {
    setOpen(open => !open);
  }, []);

  const openEditQuestionModal = useCallback(() => {
    setUpdatingQuestion(true);
  }, []);

  const closeEditQuestionModal = useCallback(() => {
    setUpdatingQuestion(false);
  }, []);

  const confirmEditQuestion = useCallback((question, imageRef) => {
    editQuestion(testId, question, imageRef, page, rowsPerPage);
  }, [testId, page, rowsPerPage, editQuestion]);

  return (
    <Fragment>
      <AddEditQuestionModal
        open={updatingQuestion}
        question={question}
        handleClose={closeEditQuestionModal}
        confirmAction={confirmEditQuestion}
      />
      <TableRow
        key={question.id}
        className={clsx(classes.row, questionReady(question) ? classes.ready : classes.notReady)}
      >
        <TableCell padding='checkbox' className={classes.checkbox}>
          <Checkbox checked={selected.includes(question.id)} onClick={(e) => selectItem(e, question.id)} />
        </TableCell>
        <TableCell padding='none'>{question.text}</TableCell>
        <TableCell>
          {
            !!question.imageUrl ?
              <Image width={102} height={102 / WH_PROPORTION} imageUrl={question.imageUrl} /> :
              t('noImage')
          }
        </TableCell>
        <TableCell padding='none'>
          <IconButton aria-label="edit" size="small" onClick={openEditQuestionModal}>
            <EditIcon />
          </IconButton>
        </TableCell>
        <TableCell padding='none'>
          <IconButton aria-label="expand row" size="small" onClick={toggleRowOpen}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <QuestionOptions question={question} testId={testId} open={open} />
    </Fragment>
  );
};

const mapStateToProps = ({ choiceTest }) => ({
  page: choiceTest.questions.page,
  rowsPerPage: choiceTest.questions.size,
  selected: choiceTest.questions.selected,
});

const mapDispatchToProps = (dispatch) => ({
  editQuestion: (testId, question, imageRef, page, rowsPerPage) => dispatch(addEditQuestion(testId, question, imageRef, page, rowsPerPage)),
  selectItem: (e, id) => dispatch(selectChoiceTestQuestion(e, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionTableRow);
