import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import CodeMirror from '@uiw/react-codemirror';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    paper: {
      margin: '10px'
    }
  })
);

function Code(props) {
  const { code, onChange, versionCounter, className } = props;
  const classes = useStyles();

  const logChange = (cm) => {
    onChange && onChange(cm.getValue());
  };

  const codeMirror = useRef(null);

  useEffect(() => {
    codeMirror.current && codeMirror.current.editor.setValue(code);
  }, [versionCounter]); // eslint-disable-line

  return (
    <Paper className={clsx(classes.paper, className)}>
      <CodeMirror
        value={code}
        ref={codeMirror}
        options={{
          theme: 'default',
          keyMap: 'sublime',
          mode: 'java',
        }}
        onChange={logChange}
      />
    </Paper>
  );
}

export default Code;
