import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LayersIcon from '@material-ui/icons/Layers';
import CheckIcon from '@material-ui/icons/Check';
import { styled } from '@material-ui/core';
import mova from 'mova';

const t = mova.ns('tasks');

const GreenIcon = styled(CheckIcon)({
  color: 'green',
});

export default function MainListItems(props) {
  const { tasks, changeActiveItem, completedTasks = [], currentTask } = props;

  const items = tasks.map(task => {
    const taskCompleted = completedTasks.indexOf(task.ordinalNumber) > -1;

    return (
      <ListItem
        button
        key={task.ordinalNumber}
        onClick={() => changeActiveItem(task)}
        selected={task.ordinalNumber === currentTask.ordinalNumber}
        style={{ paddingLeft: '8px' }}
      >
        <ListItemIcon style={{ minWidth: '30px' }}>
          {!taskCompleted && <LayersIcon />}
          {!!taskCompleted && <GreenIcon />}
        </ListItemIcon>
        <ListItemText primary={`${task.ordinalNumber}. ${t(task.ordinalNumber.toString())}`} />
      </ListItem>
    );
  });

  return (
    <div>
      {items}
    </div>
  );
};
