import Actions from '../actions/actionTypes';

const initialState = {
  content: [],
  fetching: true
};

function choiceTests(state = initialState, action) {
  switch (action.type) {
    case Actions.SAVE_CHOICE_TESTS:
      return { ...state, content: action.payload };
    case Actions.CHOICE_TESTS_FETCHING:
      return { ...state, fetching: action.payload };
    case Actions.NEW_CHOICE_TEST:
      return { ...state, content: [action.payload, ...state.content] };
    case Actions.UPDATE_CHOICE_TEST:
      const indexToUpdate = state.content.findIndex(t => t.id === action.payload.id);
      const newArray = [...state.content];
      newArray[indexToUpdate] = { ...action.payload };
      return { ...state, content: newArray };
    case Actions.CHOICE_TEST_DELETED:
      return { ...state, content: state.content.filter(t => t.id !== action.payload.id) };
    default:
      return state;
  }
}

export default choiceTests;
