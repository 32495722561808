import React, { useRef } from 'react';
import { Form, Formik } from 'formik';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import mova from 'mova';
import FormikInput from '../../common/FormikInput/FormikInput';
import ImagePreview from '../ImagePreview/ImagePreview';
import * as yup from 'yup';
import FormikCheckbox from '../../common/FormikCheckbox/FormikCheckbox';

const t = mova.ns('admin.forms');

const schema = yup.object().shape({
  text: yup.string(),
  correct: yup.boolean().required(() => t('validation.required')),
});

const AddEditOptionModal = ({ open, handleClose, confirmAction, option }) => {
  const imageRef = useRef(null);

  const confirm = (values) => {
    confirmAction({ ...(option || {}), ...values }, imageRef);
    handleClose();
  };

  return (
    <Dialog fullWidth onClose={handleClose} aria-labelledby='add-edit-option' open={open}>
      <DialogTitle id='add-edit-option'>{t(!!option ? 'editOption' : 'addOption')}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            text: (option && option.text) || '',
            correct: (option && option.correct) || false,
            imageUrl: ''
          }}
          validationSchema={schema}
          onSubmit={confirm}
        >
          {(props) => {
            const handleCloseImg = () => {
              props.setFieldValue('imageUrl', '');
            };

            return (
              <Form>
                <FormikInput
                  variant='outlined'
                  name='text'
                  label={t('optionPlaceholder')}
                  placeholder={t('optionPlaceholder')}
                  fullWidth
                />
                <FormikCheckbox
                  name='correct'
                  label={t('isCorrectPlaceholder')}
                />
                <FormikInput
                  inputProps={{ accept: 'image/*' }}
                  variant='outlined'
                  name='imageUrl'
                  type='file'
                  fullWidth
                  inputRef={imageRef}
                />
                {!!props.values.imageUrl &&
                <ImagePreview inputRef={imageRef} close={handleCloseImg} alt='Option image' />}

                <Button type='submit' variant='contained' color='secondary'>{t(!!option ? 'edit' : 'add')}</Button>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditOptionModal;
