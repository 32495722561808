import React, { memo } from 'react';
import { WH_PROPORTION } from '../../../util/constants';
import { makeStyles } from '@material-ui/core/styles';
import Image from '../Image/Image';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles((theme) => ({
  image: {
    borderRadius: '6px',
    cursor: 'pointer'
  },
  imageOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '6px',
    cursor: 'pointer',
    zIndex: 1
  },
  iconBackground: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    backgroundColor: '#EEC02D',
  }
}));

const AnswerOptionImage = ({ option, onClick, value }) => {
  const classes = useStyles();
  
  return (
    <div onClick={() => onClick(option.id)} style={{ position: 'relative' }}>
      <Image imageUrl={option.imageUrl} width={294} height={294 / WH_PROPORTION} className={classes.image} />
      {
        value.includes(option.id) && (
          <div className={classes.imageOverlay}>
            <div className={classes.iconBackground}><DoneIcon fontSize='large' /></div>
          </div>
        )
      }
    </div>
  );
};

export default memo(AnswerOptionImage);

