export const testScoreLabel = (test) => (
  test ?
    `${test.totalResult} / ${test.maxResult} (${(test.totalResult / test.maxResult * 100).toFixed(0)}%)` :
    ''
);

export const choiceTestScoreLabel = (test) => (
  test ?
    `${test.result} / ${test.numberOfQuestions} (${(test.result / test.numberOfQuestions * 100).toFixed(0)}%)` :
    ''
);
