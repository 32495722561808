import Actions from '../actions/actionTypes';

function config(state = {}, action) {
  switch (action.type) {
    case Actions.CONFIG_FETCHED:
      return action.payload;
    default:
      return state;
  }
}

export default config;
